import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Space } from 'antd'
import { Network } from 'vis-network'
import MachineModal from './machineModal'
import VpnModal from './vpnModal'
import MapObjectModal from './mapObjectModal'
import MapNodeModal from './mapNodeModal'
import SubmitFlagForm from '../../../styledComponents/sections/home/submitFlagForm'
import DoneModal from './doneModal'
import StyledLoader from '../../../styledComponents/common/loader'
import { messageTypes } from '../../../actions/messages'
import { openNotificationPopup, getCookie } from '../../../actions/helpers'
import {
  mapGet, mapSubmitFlag, userStackMapObjectsGet, machinesGet, mapObjectFileDownload,
  machineFileDownload
} from '../../../actions'
import MapLegendModal from '../../../styledComponents/sections/home/mapLegendModal'
import { InfoCircleOutlined } from '@ant-design/icons'
import {
  setMachineModalVisible, setVpnModalVisible, setMapLegendModalVisible,
  setMapObjectModalVisible, setMapNodeModalVisible, setDoneModalVisible,
  setChallengeModalVisible, setScenarioModalVisible, setScenarioElement, setChangedVms
} from '../../../store/actions'
import { options, prepareNodes, prepareEdges } from '../../../styledComponents/sections/settings/maps/mapDraw'
import ChallengeModal from '../challenges/modal'
import ScenarioModal from '../scenarios/descModal'

class Map extends Component {
  constructor (props) {
    super(props)
    this.state = {
      vpnElement: null,
      mapObjectElement: null,
      machineElement: null,
      vpns: [],
      mapObjects: [],
      machines: [],
      machinesLoaded: false,
      allowMachineStateStatus: {},
      mapNodeElement: null,
      mapLoaded: false,
      doneAllFlags: false,
      map: { edgesData: [], nodesData: [] },
      ctfSubmitFlag: false,
      nodes: undefined,
      mapChallengeNodeElement: null
    }
    // create ref for map
    this.network_ref = React.createRef()
    this.last_lang = this.props.language
    this.last_stack = null
    this.network = null
  }

  toggleMachineModal = (flag, machine) => {
    this.props.setMachineModalVisible(flag)
    this.setState({ machineElement: machine })
  }

  toggleVpnModal = (flag, vpn, machine) => {
    this.props.setVpnModalVisible(flag)
    this.setState({ vpnElement: vpn, machineElement: machine })
  }

  toggleMapObjectModal = (flag, mapObject) => {
    this.props.setMapObjectModalVisible(flag)
    this.setState({ mapObjectElement: mapObject })
  }

  toggleMapNodeModal = (flag, vpn, machine) => {
    this.props.setMapNodeModalVisible(flag)
    this.setState({ mapNodeElement: vpn })
  }

  toggleMapChallengeNodeModal = (flag, node) => {
    this.props.setChallengeModalVisible(flag)
    this.setState({ mapChallengeNodeElement: node })
  }

  toggleMapScenarioNodeModal = (flag, node) => {
    this.props.setScenarioModalVisible(flag)

    const scenario = node?.scenario

    if (scenario) {
      scenario.resources = { net: this.state.machines?.filter(vm => scenario.machines_by_name?.includes(vm.machine_name)) }
    }

    this.props.setScenarioElement(scenario)
  }

  toggleDoneModal = (flag) => {
    this.props.setDoneModalVisible(flag)
  }

  toggleMapLegendModal = (visible) => {
    this.props.setMapLegendModalVisible(visible)
  }

  fitMap2 = () => {
    const options = {
      animation: {
        duration: 1000,
        easingFunction: 'easeInOutQuad'
      }
    }

    this.network.fit(options)
    //
    // const options = {
    //   position: {x:400, y:700},
    //   scale: 0.5,
    //     animation: {
    //       duration: 1000,
    //       easingFunction: 'easeInOutQuad',
    //     },
    //
    // }
    //
    // this.network.moveTo(options)
  }

  fitMap = () => {
    // const options = {
    //   animation: {
    //     duration: 1000,
    //     easingFunction: 'easeInOutQuad',
    //   },
    //   minZoomLevel: 0.5,
    // }
    // this.network.fit(options)

    const options = {
      position: {x:400, y:-2700},
      scale: 0.8,
        animation: {
          duration: 1000,
          easingFunction: 'easeInOutQuad',
        },

    }

    this.network.moveTo(options)
  }

  copyToClipboard = (str) => {
    const el = document.createElement('textarea')
    el.value = str
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    // show notify
    openNotificationPopup(messageTypes[this.props.language].copied_to_clipboard, str, 'smile')
  }

  isClickableNode = (clickedNode) => {
    // check if node has sub segments with _ char
    if (clickedNode.length > 5 && clickedNode.indexOf('_') > -1) {
      const nodeSplited = clickedNode.split('_')

      // if yes and second part is status (it mean that is simple status of vm)
      // and clicked node should be overwriten to name without 'status' suffix
      if (nodeSplited.length > 1 && nodeSplited[nodeSplited.length - 1] === 'status') {
        clickedNode = nodeSplited.slice(0, -1).join('_')
      }
    }

    // find node by id in all map nodes
    const node = this.state.map.nodesData.find(x => x.id === clickedNode)

    if (node) {
      // determine type of map node
      if (node.type === 'vm') {
        // check if clicked node matches machine name
        const machine = this.state.machines.find(x => x.name.pl.toLowerCase() === clickedNode)
        if (machine) {
          return { entity: machine, type: 'machine' }
        } else {
          return { entity: { disabled: true }, type: 'machine' }
        }
      } else if (node.type === 'vpn') {
        // check if clicked node matches vpn network name
        const vpn = this.props.stackVpns && this.props.stackVpns.find(x => x.network_name === clickedNode)

        if (vpn) {
          return {
            entity: vpn,
            type: 'vpn',
            machine_entity: this.state.machines.find(x => x.name.pl.toLowerCase() === (vpn.vpn_vm_name && vpn.vpn_vm_name.toLowerCase()))
          }
        } else {
          return { entity: { disabled: true }, machine_entity: { disabled: true }, type: 'vpn' }
        }
      } else if (node.type === 'map_object') {
        // check if clicked node matches map object
        const mapObject = this.state.mapObjects.find(x => x.map_id === String(clickedNode))
        if (mapObject) {
          return {
            entity: mapObject,
            type: 'mapObject'
          }
        }

        const mapNode = this.state.map.nodesData && this.state.map.nodesData.find(x => x.id === clickedNode)

        if (mapNode && mapNode.flags && mapNode.flags.length > 0) {
          return {
            entity: mapNode,
            type: 'map_node'
          }
        }
      } else if (node.type === 'challenge') {
        const mapNode = this.state.map.nodesData && this.state.map.nodesData.find(x => x.id === clickedNode)

        if (mapNode && mapNode.challenge?.active) {
          return {
            entity: mapNode,
            type: 'map_challenge'
          }
        }
      } else if (node.type === 'scenario') {
        const mapNode = this.state.map.nodesData && this.state.map.nodesData.find(x => x.id === clickedNode)

        if (mapNode && mapNode.scenario) {
          return {
            entity: mapNode,
            type: 'map_scenario'
          }
        }
      }
    }

    // if no match, node is not clickable
    return false
  }

  buildNetwork () {
    const nodesData = this.state.map.nodesData
    const edgesData = this.state.map.edgesData
    const iconsData = this.state.map.iconsData
    const nodesPrepared = prepareNodes(nodesData, this.isClickableNode, this.props.language, false, this.state.machines, this.props.stack?.type, iconsData)
    const nodes = nodesPrepared.nodes
    const edges = prepareEdges(nodesData, edgesData)

    // create a network
    const data = {
      nodes,
      edges
    }

    options.groups = {
      ...options.groups,
      ...iconsData
    }

    const _this = this

    // map initialization
    this.network = new Network(this.network_ref.current, data, options)

    // on right mouse click, fit map to window
    this.network.on('oncontext', function (params) {
      params.event.preventDefault()
      _this.fitMap()
    })

    // on right mouse click, fit map to window
    this.network.on('zoom', function (params) {
      const zoomInLimit = 1
      const zoomOutLimit = 0.15

      if(_this.network.getScale() >= zoomInLimit) {
        const options = {
          scale: zoomInLimit
        }

        _this.network.moveTo(options)
      }

      if(_this.network.getScale() < zoomOutLimit) {
        const options = {
          scale: zoomOutLimit
        }

        // _this.network.moveTo(options)
        _this.fitMap2()
      }
    })

    this.network.on('selectNode', function (params) {
      if (params.nodes.length > 0) {
        const clickedNode = params.nodes[0]
        const clickedNodeObject = nodes.get(clickedNode)
        const clickedElement = _this.isClickableNode(clickedNode)

        // not show modal on non-string nodes and on vpn nodes
        if (clickedElement) {
          if (clickedElement.type === 'vpn') {
            _this.toggleVpnModal(true, clickedElement.entity, clickedElement.machine_entity)
          } else if (clickedElement.type === 'mapObject') {
            _this.toggleMapObjectModal(true, clickedElement.entity)
            // _this.modalPopup(clickedNode);
          } else if (clickedElement.type === 'machine') {
            // _this.toggleMapObjectModal(true, clickedNode);
            _this.toggleMachineModal(true, clickedElement.entity)
          } else if (clickedElement.type === 'map_node') {
            _this.toggleMapNodeModal(true, clickedElement.entity)
          } else if (clickedElement.type === 'map_challenge') {
            _this.toggleMapChallengeNodeModal(true, clickedElement.entity)
          } else if (clickedElement.type === 'map_scenario') {
            _this.toggleMapScenarioNodeModal(true, clickedElement.entity)
          }
        } else if (clickedNodeObject.toCopy !== undefined) {
          _this.copyToClipboard(clickedNodeObject.toCopy)
        }
      }
      // unselect all nodes to allow select node again
      _this.network.unselectAll()
    })

    // canvas handle is needed to allow cursor changing
    const networkCanvas = this.network_ref.current.getElementsByTagName('canvas')[0]

    // simple function to change cursor over node
    function changeCursor (newCursorStyle) {
      networkCanvas.style.cursor = newCursorStyle
    }

    // hover node event
    this.network.on('hoverNode', function (params) {
      if (params.node) {
        const clickedNode = params.node

        if (_this.isClickableNode(clickedNode)) {
          changeCursor('pointer')
        } else if (clickedNode > 8000 && clickedNode < 9000) {
          changeCursor('pointer')
        }
      }
    })

    // blur node event
    this.network.on('blurNode', function (params) {
      changeCursor('default')
    })

	  console.log('TTTTT', nodes)
    if (nodes.length) {
      this.setState({ mapLoaded: true, nodes })
    }

    this.fitMap()
  }

  submitFlag = (flag) => {
    mapSubmitFlag(flag, getCookie('_token')).then((json) => {
      if (json.status === 'ok') {
        if (json.all_done || json.gold_flag) {
          this.toggleDoneModal(true)
          this.setState({ doneAllFlags: json.all_done })
        }

        openNotificationPopup(messageTypes[this.props.language].success, json.response[this.props.language], 'smile')

        this.loadMap()
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[this.props.language].oops, json.response[this.props.language], 'frown')
      }
    })
  }

  loadMap = () => {
    this.setState({
      map: { nodesData: [], edgesData: [], iconsData: [] },
      mapLoaded: false,
      ctfSubmitFlag: false,
      maps: undefined
    })
    if (this.network !== null) {
      this.network.destroy()
    }

    mapGet(getCookie('_token')).then((json) => {
      if (json.status === 'ok') {
        const maps = json.response.maps && Object.keys(json.response.maps)
        const selectedMap = maps?.length > 0 && maps[0]

        this.setState({
          map: {
            nodesDataOrg: json.response.nodes,
            nodesData: (maps?.length > 0 && json.response.nodes.filter(x => x.map === selectedMap)) || json.response.nodes,
            edgesDataOrg: json.response.edges,
            edgesData: (maps?.length > 0 && json.response.edges.filter(x => x.map === selectedMap)) || json.response.edges,
            iconsData: json.response.icons
          },
          maps: json.response.maps,
          selectedMap,
          ctfSubmitFlag: !!json.response.flag_submit_enabled
        })

        this.getAllMachines()

        if (this.state.mapChallengeNodeElement) {
          const mapChallengeNodeElement = json.response.nodes.find(x => x.id === this.state.mapChallengeNodeElement.id)
          this.setState({ mapChallengeNodeElement })
        }
      } else if (json.status === 'err') {
        openNotificationPopup(
          messageTypes[this.props.language].oops,
          json.response[this.props.language],
          'frown'
        )
      }
    })
  }

  getStackMapObjects = () => {
    userStackMapObjectsGet(getCookie('_token')).then((json) => {
      if (json.status === 'ok') {
        this.setState({
          mapObjects: json.response
        })
      } else if (json.status === 'err') {
        openNotificationPopup(
          messageTypes[this.props.language].oops,
          json.response[this.props.language],
          'frown'
        )
      }
    })
  }

  setAllowMachineStateStatus = (vmUuid) => {
    const allowMachineStateStatus = this.state.allowMachineStateStatus
    allowMachineStateStatus[vmUuid] = true
    this.setState({ allowMachineStateStatus })
  }

  clearAllowMachineStateStatus = (vmUuid) => {
    const allowMachineStateStatus = this.state.allowMachineStateStatus

    if (allowMachineStateStatus[vmUuid] === true) {
      delete allowMachineStateStatus[vmUuid]

      this.setState({ allowMachineStateStatus })
    }
  }

  getAllMachines = (stackAction = false, vmUuid = null, afterSuccess = null) => {
    this.setState({
      machines: [],
      machinesLoaded: false
    })
    machinesGet(getCookie('_token')).then((json) => {
      if (json.status === 'ok') {
        this.setState({
          machines: json.response,
          machinesLoaded: true
        })
        // if machine modal was visible during get machines, refresh opened machine object
        if ((this.props.machineModalVisible || this.props.vpnModalVisible) && this.state.machineElement) {
          const machineElement = this.state.machines.find(x => x.id === this.state.machineElement.id)
          this.setState({
            machineElement
          })
        }

        // if machine id is set, remove allow to set custom status
        if (vmUuid !== null) {
          this.clearAllowMachineStateStatus(vmUuid)
        }

        // if callback after machines get success is set, execute it
        if (afterSuccess !== null) {
          afterSuccess()
        }

        this.network_ref?.current && this.buildNetwork()
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[this.props.language].oops, json.response[this.props.language], 'frown')
      }
    })
  }

  componentDidMount () {
    // get needed data
    this.getStackMapObjects()
    this.loadMap()
  }

  componentDidUpdate (prevProps, prevState) {
    const { stack, ctfState } = this.props

    if ((!this.last_stack && stack) || (this.last_stack && stack && this.last_stack._id !== stack._id) || (ctfState !== prevProps.ctfState && ctfState === 'BATTLE')) {
      this.last_stack = stack

      // get needed data
      this.getStackMapObjects()
      this.loadMap()
    }

    // if there are vms statuses to update, update DataSet with nodes
    if (prevProps.changedVms !== this.props.changedVms) {
      const changedVms = this.props.changedVms
      const nodes = this.state.nodes

      // update machines statuses on map
      changedVms.data.forEach((item, i) => {
        // if element is vpn type, use network_name as id otherwise use vm name
        const id = item.network_name || item.name.pl.toLowerCase()

        if (item.resources.status === 'poweredOn') {
          nodes.update({ id: id + '_status', group: 'node_status_green' })
        } else if (item.resources.status === 'poweredOff' || item.resources.status === 'suspended' || item.resources.status === 'failed') {
          nodes.update({ id: id + '_status', group: 'node_status_red' })
        } else {
          nodes.update({ id: id + '_status', group: 'node_status_yellow' })
        }
      })

      // update statuses in machines list
      const machines = this.state.machines

      changedVms.data.forEach((item, i) => {
        const vm = machines.find(x => x.name.pl.toLowerCase() === item.name.pl.toLowerCase())

        if (vm) {
          vm.resources.status = item.resources.status
        }
      })

      this.setState({ nodes, machines })
    }
  }

  machineRefresh = (vmId, status) => {
    if (vmId && status) {
      const machineElement = this.state.machineElement

      if (machineElement) {
        machineElement.resources.status = status
        this.props.setChangedVms({ data: [machineElement] })
      }
    }
  }

  scenarioRefresh = (vmId, status) => {
    const scenarioElement = this.props.scenarioElement
    const vmToUpdate = scenarioElement.resources?.net?.find(vm => vm.id === vmId)

    if (vmToUpdate) {
      vmToUpdate.resources.status = status
      this.props.setChangedVms({ data: [vmToUpdate] })
    }

    this.props.setScenarioElement(scenarioElement)
  }

  render () {
    const { language, stack } = this.props
    const { maps, selectedMap } = this.state

    // if lang changed build map again
    if (this.last_lang !== language) {
      if (this.network !== null) {
        this.network.destroy()
      }
      // build map only when map element is available
      if (this.network_ref.current !== null) {
        this.buildNetwork()
      }
    }

    // remember lang
    this.last_lang = language


	  console.log('RRRRRRRRR', this.state.machinesLoaded, this.state.mapLoaded)

    return (
      <>
        <MachineModal
          language={this.props.language}
          visible={this.props.machineModalVisible}
          element={this.state.machineElement}
          machineElement={this.state.machineElement}
          toggleModal={this.toggleMachineModal}
          setAllowStateStatus={this.setAllowMachineStateStatus}
          allowStateStatus={this.state.allowMachineStateStatus}
          clearAllowMachineStateStatus={this.clearAllowMachineStateStatus}
          downloadFile={machineFileDownload}
          stack={stack}
          refreshData={this.machineRefresh}
        />
        <VpnModal
          language={this.props.language}
          visible={this.props.vpnModalVisible}
          element={this.state.vpnElement}
          machineElement={this.state.machineElement}
          toggleModal={this.toggleVpnModal}
          setAllowStateStatus={this.setAllowMachineStateStatus}
          allowStateStatus={this.state.allowMachineStateStatus}
          clearAllowMachineStateStatus={this.clearAllowMachineStateStatus}
          stack={stack}
        />
        <MapObjectModal
          language={this.props.language}
          visible={this.props.mapObjectModalVisible}
          element={this.state.mapObjectElement}
          toggleModal={this.toggleMapObjectModal}
          downloadFile={mapObjectFileDownload}
        />
        <MapNodeModal
          language={this.props.language}
          visible={this.props.mapNodeModalVisible}
          element={this.state.mapNodeElement}
          toggleModal={this.toggleMapNodeModal}
        />
        <DoneModal
          language={this.props.language}
          visible={this.props.doneModalVisible}
          toggleModal={this.toggleDoneModal}
          doneAllFlags={this.state.doneAllFlags}
        />
        <ChallengeModal
          refreshData={this.loadMap}
          element={this.state.mapChallengeNodeElement?.challenge}
          toggleModal={this.toggleMapChallengeNodeModal}
        />
        <ScenarioModal
          refreshData={this.scenarioRefresh}
        />
        {this.state.mapLoaded && maps && Object.keys(maps).length > 0 &&
          (
            <div style={{ textAlign: 'left', marginBottom: '20px' }} className={'ranking-actions'}>
              <Space>
                {Object.keys(maps).map(map => <Button
                  onClick={() => {
                      this.setState({
                        selectedMap: map,
                        map: {
                          nodesDataOrg: this.state.map.nodesDataOrg,
                          nodesData: this.state.map.nodesDataOrg.filter(x => x.map === map),
                          edgesDataOrg: this.state.map.edgesDataOrg,
                          edgesData: this.state.map.edgesDataOrg.filter(x => x.map === map),
                          iconsData: this.state.map.iconsData
                        }
                      }, () => this.buildNetwork())
                  }}
                  className={(selectedMap === map && 'view-selected') || ''}
                >
                  {maps[map][language]}
                </Button>)}
              </Space>
            </div>)}
        {stack && stack.map_legend && stack.map_legend[language]
          ? (
            <Button type='primary' onClick={() => { this.toggleMapLegendModal(true) }} className='map-legend-button'>
              <InfoCircleOutlined />{messageTypes[language].map_legend}
            </Button>)
          : ''}
        {this.state.ctfSubmitFlag === true && stack !== 'switching' && stack?.ctf_type === 'netwars'
          ? (
            <SubmitFlagForm
              language={this.props.language}
              submitFlag={this.submitFlag}
            />)
          : ''}
        <MapLegendModal
          language={language}
          visible={this.props.mapLegendModalVisible}
          icons={this.state.map.iconsData}
          handleMapLegendVisibility={this.toggleMapLegendModal}
        />
        <div
          className='map-container'
          style={stack && stack.map_bg_filename
            ? {
                backgroundImage: 'url(/cp_images/' + stack.type + '_' + stack.content_pack_version + '_' + stack.map_bg_filename + ')'
              }
            : {}}
        >
          <div ref={this.network_ref} className={`map ${this.state.machinesLoaded && this.state.mapLoaded && stack !== 'switching' ? 'fade-in' : ''}`} />
        </div>

        <StyledLoader fadeOut={this.state.machinesLoaded && this.state.mapLoaded && stack !== 'switching'} />
      </>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language,
  auth: state.hdStore.auth,
  stackVpns: state.hdStore.stackVpns,
  stack: state.hdStore.stack,
  machineModalVisible: state.hdStore.machineModalVisible,
  vpnModalVisible: state.hdStore.vpnModalVisible,
  mapLegendModalVisible: state.hdStore.mapLegendModalVisible,
  mapObjectModalVisible: state.hdStore.mapObjectModalVisible,
  mapNodeModalVisible: state.hdStore.mapNodeModalVisible,
  doneModalVisible: state.hdStore.doneModalVisible,
  scenarioModalVisible: state.hdStore.scenarioModalVisible,
  changedVms: state.hdStore.changedVms,
  ctfState: state.hdStore.ctfState,
  scenarioElement: state.hdStore.scenarioElement
})

const mapDispatchToProps = {
  // setCurrentStackMachines,
  setMachineModalVisible,
  setVpnModalVisible,
  setMapLegendModalVisible,
  setMapObjectModalVisible,
  setMapNodeModalVisible,
  setDoneModalVisible,
  setChallengeModalVisible,
  setScenarioModalVisible,
  setScenarioElement,
  setChangedVms
}

const MapContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Map)

export default MapContainer
