import React, { Component } from 'react'
import { Switch, Tooltip } from 'antd'
import { messageTypes } from '../../actions/messages.js'

class StyledPowerSwitch2 extends Component {
  render () {
    const { status, language, power, stack, stackActionStatus } = this.props
    const powerDisabled = power === false || stack?.status !== 'poweredon' ||
      (
        status !== 'poweredOn' &&
        status !== 'poweredOff' &&
        status !== 'suspended') || stackActionStatus !== undefined

    return (
      <Tooltip placement='top' title={powerDisabled ? (stackActionStatus !== undefined ? messageTypes[this.props.language].power_is_disabled_stack_turned_off : messageTypes[this.props.language].power_is_disabled) : ''}>
        <div
          onClick={() => powerDisabled ? null : this.props.handleSwitch(this.props.id)}
          disabled={powerDisabled}
          className={powerDisabled ? 'vm-action-disabled' : ''}
        >
          {status === 'poweredOn' ? 'Wyłącz' : 'Włącz'}
        </div>
      </Tooltip>
    )
  }
}

export default StyledPowerSwitch2
