import React, { Component } from 'react'
import { connect } from 'react-redux'
import StyledNavbar from '../../styledComponents/common/navbar'
import { setSearchPassphrase } from '../../store/actions'
import StackSwitchComponent from './stackSwitchComponent'
import { /* setCurrentStackMachines, */ setStack, setStackActionStatus, setStackPrev } from '../../store/actions'

// class Navbar extends Component {
  class Navbar extends StackSwitchComponent {

  render () {
    const { auth, language, stack, userStacks } = this.props

// console.log('EEEE', this.props)

    return (
      <StyledNavbar
        auth={auth}
        language={language}
        stack={stack}
        stacks={userStacks}
        switchStack={this.switchStack}
      />
    )
  }
}

const mapStateToProps = state => ({
  auth: state.hdStore.auth,
  language: state.hdStore.language,
  searchPassphrase: state.hdStore.searchPassphrase,
  userStacks: state.hdStore.user_stacks,
  stack: state.hdStore.stack,
  stackPrev: state.hdStore.stackPrev,
})

const mapDispatchToProps = {
  setSearchPassphrase,
  setStack,
  setStackPrev,
  setStackActionStatus
}

const NavbarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar)

export default NavbarContainer
