import React from 'react'
import { Modal } from 'antd'
import { messageTypes } from '../../../../actions/messages'
import StyledChallengeForm from '../../../../styledComponents/sections/settings/semesterClass/form'
import { adminGetContentPacks, adminGetNormalAdminUsers } from '../../../../actions/'
import { takeElementWithIdFromArr, getCookie, openNotificationPopup } from '../../../../actions/helpers'

class SemesterClassModal extends React.Component {
  state = {
    singleData: {},
    overlordStackTypes: []
  }

  handleCancel = () => {
    const { setDataId, showAddRecordModal } = this.props
    setDataId(null)
    showAddRecordModal(false)
  }

  componentDidMount () {
    this._isMounted = true

    this.getContentPacks()
    this.getNormalAdminUsers()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  getContentPacks = () => {
    const { language } = this.props

    adminGetContentPacks(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            overlordStackTypes: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  getNormalAdminUsers = () => {
    const { language } = this.props

    adminGetNormalAdminUsers(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            leaders: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  componentDidUpdate (prevProps, prevState) {
    const { dataId, data } = this.props

    if (prevProps.dataId !== dataId) {
      const singleData = takeElementWithIdFromArr(dataId, data)
      this.setState({ singleData })
    }
  }

  render () {
    const {
      language, dataId, isNewRecord, loading, updateRecord, addRecord
    } = this.props
    const { singleData } = this.state

    return (
      <div>
        <Modal
          maskClosable={false}
          width={1000}
          visible={!!(dataId || isNewRecord)}
          title={(singleData?.name && singleData?.name[language]) || messageTypes[language].course_group}
          onCancel={this.handleCancel}
          footer={null}
        >
          <StyledChallengeForm
            isNewRecord={isNewRecord}
            dataId={dataId}
            data={singleData || {}}
            language={language}
            onCancel={this.handleCancel}
            updateRecord={updateRecord}
            addRecord={addRecord}
            loading={loading}
            overlordStackTypes={this.state.overlordStackTypes}
            leaders={this.state.leaders}
          />
        </Modal>
      </div>
    )
  }
}

export default SemesterClassModal
