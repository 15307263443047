import React from 'react'
import { messageTypes } from '../../../../actions/messages'
import ResourceFilesFormComponent from '../../../common/filesWithUploadForm'
import {
  Form, Select, Input, InputNumber, Button, Divider, Tooltip
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const { Option } = Select
const { TextArea } = Input

class StyledMachinesForm extends ResourceFilesFormComponent {
  formRef = React.createRef()

  componentDidUpdate (prevProps, prevState) {
    const { data, isNewRecord, contentPackVersions } = this.props

    if (data._id !== prevProps.data._id || isNewRecord !== prevProps.isNewRecord) {
      this.setDefaultValues()
    }

    if (data._id !== prevProps.data._id || data === null) {
      this.setState({ fromDisabled: false })
    }

    if (contentPackVersions?.length > prevProps.contentPackVersions?.length) {
      const fromDisabled = contentPackVersions?.find(x => x.name === data.content_pack_version)?.status === 'final'

      this.setState({ fromDisabled })
    }
  }

  prepareFormValues (values) {
    return {
      desc: { pl: values.desc_pl, en: values.desc_en },
      machine_name: values.machine_name,
      order: values.order,
      console: values.console,
      stack_type: values.stack_type,
      content_pack_version: values.content_pack_version,
      active: values.active === 'yes'
    }
  }

  prepareFormExistingValues (data) {
    return {
      desc_pl: data.desc ? data.desc.pl : '',
      desc_en: data.desc ? data.desc.en : '',
      machine_name: data.machine_name,
      content_pack_version: data.content_pack_version,
      stack_type: data.stack_type,
      order: data.order ? data.order : 0,
      console: data.console || 'default',
      active: ((data.active || data.active === undefined) && 'yes') || 'no'
    }
  }

  prepareFormDefaultValues () {
    return {
      desc_pl: '',
      desc_en: '',
      machine_name: '',
      stack_type: undefined,
      content_pack_version: undefined,
      order: 0,
      console: 'disabled',
      active: 'yes'
    }
  }

  addFile = (challengeId, callAfterCreate) => {
    const { addFile } = this.props
    addFile && addFile(challengeId).then(result => callAfterCreate(result))
  }

  removeSingleFile = (challengeId, lang, callAfterCreate) => {
    const { removeSingleFile } = this.props
    removeSingleFile && removeSingleFile(challengeId, lang).then(result => callAfterCreate && callAfterCreate(result))
  }

  removeResource = (challengeId, callAfterCreate) => {
    const { removeResource } = this.props
    removeResource && removeResource(challengeId).then(result => callAfterCreate && callAfterCreate(result))
  }

  // handleStackTypesChange = (selectedStackTypes) => {
  //   this.setState({ selectedStackTypes })
  //
  //   this.props.getMachinesForStackTypes(this.state.machineName || this.props.data?.machine_name, selectedStackTypes)
  // }

  handleStackTypeChange = (stackType) => {
    this.setState({ stackType })

    this.props.getMachinesForStackTypes(this.state.machineName || this.props.data?.machine_name, [stackType])
    this.props.getContentPackVersions(stackType)
  }

  handleMachineNameChange = (machineName) => {
    this.setState({ machineName })

    this.props.getMachinesForStackTypes(machineName, [this.state.stackType])
  }

  adminCopyResourceFileToLang = (id, lang, dstLang) => {
    this.props.adminCopyMachineResourceFileToLang(id, lang, dstLang)
  }

  render () {
    const { language, loading, machines, singleDataVmware, data, isNewRecord, overlordStackTypes } = this.props
    const { fromDisabled } = this.state

    return (
      <Form onFinish={this.handleSubmit} layout='horizontal' ref={this.formRef}>

        {/* <Form.Item
          name='stack_types'
          label={messageTypes[language].stack_types}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <Select onChange={this.handleStackTypesChange} mode='multiple'>
            {
              settings.stack_types.length
                ? settings.stack_types.map((stackType, n) =>
                  <Option key={n} value={stackType}>{stackType}</Option>
                )
                : ''
            }
          </Select>
        </Form.Item> */}

        <Form.Item
          name='machine_name'
          label={messageTypes[language].machine_name}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <Select placeholder={messageTypes[language].machines} disabled={machines === undefined || fromDisabled} onChange={this.handleMachineNameChange}>
            {
              machines?.map((vm, i) => (
                <Option key={i} value={vm.name.toLowerCase()}>{vm.name}</Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          name='desc_pl'
          label={messageTypes[language].desc_pl}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <TextArea rows={6} disabled={fromDisabled} />
        </Form.Item>

        <Form.Item
          name='desc_en'
          label={messageTypes[language].desc_en}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <TextArea rows={6} disabled={fromDisabled} />
        </Form.Item>

        <Form.Item
          name='console'
          label={messageTypes[language].console}
          {...this.formItemLayout}
        >
          {((singleDataVmware === undefined) &&
            <Tooltip placement='top' title={messageTypes[language].machine_not_available_in_stack}>
              <Select disabled defaultValue={data?.console}>
                <Option key='default' value='default'>włączona</Option>
                <Option key='disabled' value='disabled'>wyłączona</Option>
              </Select>
            </Tooltip>) || (
              <Select disabled={singleDataVmware?.console === false}>
                <Option key='default' value='default'>{((singleDataVmware?.console === false && 'wyłączona - ') || (singleDataVmware?.console === true && 'włączona - '))}domyślne ustawienie stacka</Option>
                <Option key='disabled' value='disabled'>wyłączona</Option>
              </Select>)}
        </Form.Item>

        <Form.Item
          name='active'
          label={messageTypes[language].active3}
          {...this.formItemLayout}
        >
          {(singleDataVmware === undefined &&
            <Tooltip placement='top' title={messageTypes[language].machine_not_available_in_stack}>
              <Select disabled defaultValue={data?.active}>
                <Option key='yes' value='yes'>tak</Option>
                <Option key='no' value='no'>nie</Option>
              </Select>
            </Tooltip>) || (
              <Select>
                <Option key='yes' value='yes'>tak</Option>
                <Option key='no' value='no'>nie</Option>
              </Select>)}
        </Form.Item>

        <Form.Item
          name='stack_type'
          label={messageTypes[language].stack_type}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <Select onChange={this.handleStackTypeChange} disabled={fromDisabled}>
            {overlordStackTypes && overlordStackTypes.length && overlordStackTypes.map((stackType, i) =>
              <Option key={i} value={stackType.name}>{stackType.name}</Option>)}
          </Select>
        </Form.Item>

        <Form.Item
          name='content_pack_version'
          label={messageTypes[language].content_pack_version}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <Select disabled={fromDisabled}>
            {this.props.contentPackVersions && this.props.contentPackVersions.length && this.props.contentPackVersions.filter(x => !fromDisabled ? x.status === 'draft' : true).map((version, i) =>
              <Option key={i} value={version.name}>{version.name}</Option>)}
          </Select>
        </Form.Item>

        <Form.Item
          name='order'
          label={messageTypes[language].order}
          {...this.formItemLayout}
        >
          <InputNumber disabled={fromDisabled} />
        </Form.Item>

        {!isNewRecord && this.fileFieldsRender('machines', data.machine_hd_id, 'machines', fromDisabled)}

        <Form.Item className='form-footer'>
          <Divider />
          <Button htmlType='submit'>{loading ? <LoadingOutlined /> : ''} {messageTypes[language].submit}</Button>
          <Button onClick={() => { this.props.onCancel() }} className='cancel'>{messageTypes[language].cancel}</Button>
        </Form.Item>
      </Form>
    )
  }
}

export default StyledMachinesForm
