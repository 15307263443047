import React from 'react'
import Highlighter from 'react-highlight-words'
import { Table, Divider, Input, Button, Tag, Dropdown, Space, Menu } from 'antd'
import { SearchOutlined, MenuOutlined } from '@ant-design/icons'
import { messageTypes } from '../../../../actions/messages'

class StyledStacksTableSettings extends React.Component {
  state = {
    columns: []
  }

  settings = {
    bordered: false,
    loading: false,
    pagination: { position: 'bottom' },
    size: 'default',
    showHeader: true
  }

  getColumnSearchProps = (dataIndex, language) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => { this.searchInput = node }}
          placeholder={messageTypes[language]['table_search_' + dataIndex]}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          {messageTypes[language].search}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size='small'
          style={{ width: 90 }}
        >
          {messageTypes[language].clear}
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined className={filtered ? 'active' : ''} />,
    onFilter: (value, record) => record[dataIndex] && ((typeof record[dataIndex] === 'object' && record[dataIndex][language]) || record[dataIndex]).toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    )
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  setColumns (language) {
    this.setState({
      columns: [
        {
          title: messageTypes[language].name_name,
          dataIndex: 'name',
          key: 'name',
          ...this.getColumnSearchProps('name', language),
          sorter: (a, b) => { return ((typeof a.name === 'object' && a.name[language]) || a.name).localeCompare(((typeof b.name === 'object' && b.name[language]) || b.name)) },
          render: (name) => (
            (typeof name === 'object' && name[language]) || name
          )
        },
        {
          title: messageTypes[language].nr,
          dataIndex: 'nr',
          key: 'nr',
          ...this.getColumnSearchProps('nr', language),
          sorter: (a, b) => { return a.nr - b.nr },
          render: (nr) => (
            nr.toString()
          )
        },
        {
          title: messageTypes[language].status,
          dataIndex: 'status',
          key: 'status',
          ...this.getColumnSearchProps('status', language),
          sorter: (a, b) => { return (!a.status && 1) || a.status.localeCompare(b.status) },
          render: (status) => (
            status && <Tag>{status}</Tag>
          )
        },
        {
          title: messageTypes[language].type,
          dataIndex: 'type',
          key: 'type',
          ...this.getColumnSearchProps('type', language),
          sorter: (a, b) => { return a.type.localeCompare(b.type) },
          render: (data, record) => (
            (record.is_ctf && record.type + ' (' + record.ctf_type + ')') || data
          )
        },
        {
          title: messageTypes[language].content_pack_version,
          dataIndex: 'content_pack_version',
          key: 'content_pack_version',
          sorter: (a, b) => { return String(a.content_pack_version).localeCompare(b.content_pack_version) },
          ...this.getColumnSearchProps('content_pack_version', language)
        },
        {
          title: messageTypes[language].used_by_users_count,
          dataIndex: 'users',
          key: 'users',
          sorter: (a, b) => { return (!a.users && 1) || (a.users.length - b.users.length) },
          render: (users) => (
            users && (users.length)
          )
        },
        {
          title: messageTypes[language].demo,
          dataIndex: 'demo',
          key: 'demo',
          ...this.getColumnSearchProps('demo', language),
          sorter: (a, b) => { return (!a.demo && 1) || a.demo.localeCompare(b.demo) }
        },
        {
          title: messageTypes[language].is_ctf,
          dataIndex: 'is_ctf_icon',
          key: 'is_ctf_icon',
          sorter: (a, b) => { return a.is_ctf !== b.is_ctf }
        },
        {
          title: messageTypes[language].actions,
          key: 'action',
          render: (text, record) => (
            <Space>
              <span>
                {record.is_ctf && record.ctf_type === 'jeopardy' && record.frozen_scoreboard &&
                  <>
                    <a href='#unfreeze-ranking' className='unfreeze-btn' onClick={() => { this.props.unfreezeRanking(record._id) }}>{messageTypes[language].unfreeze_ranking}</a>
                    <Divider type='vertical' />
                  </>}
                {record.is_ctf && record.ctf_type === 'jeopardy' && !record.frozen_scoreboard &&
                  <>
                    <a href='#freeze-ranking' className='freeze-btn' onClick={() => { this.props.freezeRanking(record._id) }}>{messageTypes[language].freeze_scoreboard}</a>
                    <Divider type='vertical' />
                  </>}
                {record.status && record.status !== 'suspended' && record.status !== 'suspending' &&
                  (
                    <>
                      <a href='#suspend-stack' className='suspend-btn' onClick={() => { this.props.executeStackAction(record._id, 'suspend') }}>{messageTypes[language].suspend}</a>
                      <Divider type='vertical' />
                    </>)}
                {record.status && record.status !== 'poweredon' && record.status !== 'poweringon' && record.status !== 'suspending' &&
                  (
                    <>
                      <a href='#poweron-stack' className='poweron-btn' onClick={() => { this.props.executeStackAction(record._id, 'poweron') }}>{messageTypes[language].poweron}</a>
                      <Divider type='vertical' />
                    </>)}
                <a href='#edit-stack' className='edit-btn' onClick={() => { this.props.setDataId(record._id) }}>{messageTypes[language].edit}</a>
                <Divider type='vertical' />
                <a href='#delete-stack' className='delete-btn' onClick={() => { this.props.deleteRecord(record._id) }}>{messageTypes[language].delete}</a>
              </span>

              {record.is_ctf &&
                <Dropdown
                  className='stack-menu-dropdown'
                  overlay={
                    <Menu>
                      <Menu.Item key={1}>
                        <a href='#clear-flags' className='clear-flags-btn' onClick={() => { this.props.clearStackCtfFlags(record._id) }}>{messageTypes[language].clear_flags}</a>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <MenuOutlined />
                </Dropdown>}
            </Space>
          )
        }]
    })
  }

  componentDidMount () {
    const { language } = this.props
    this._isMounted = true
    this.setColumns(language)
  }

  componentDidUpdate (prevProps, prevState) {
    const { language } = this.props
    if (this._isMounted && language !== prevProps.language) {
      this.setColumns(language)
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { columns } = this.state
    const { data, language } = this.props

    return (
      <Table
        className={`settings-table ${data && data.length ? 'fade-in' : ''}`}
        {...this.settings}
        columns={columns}
        expandable={{
          expandedRowRender: record => <p style={{ margin: 0 }}>{messageTypes[language].used_by_users}: {record.users && record.users.map((user) => { return user.name }).join(', ')}</p>,
          rowExpandable: record => record.users && record.users.length
        }}
        dataSource={data}
        locale={{
          triggerDesc: messageTypes[language].table_sort_desc,
          triggerAsc: messageTypes[language].table_sort_asc,
          cancelSort: messageTypes[language].table_sort_cancel
        }}
      />
    )
  }
}

export default StyledStacksTableSettings
