import React, { Component } from 'react'
import { connect } from 'react-redux'
import StyledSemesterTable from '../../../../styledComponents/sections/settings/semesters/table'
import AddRecordButton from '../../../../styledComponents/common/addRecordButton'
import {
  openNotificationPopup,
  getCookie,
  confirm,
  deleteElementWithIdFromArr
} from '../../../../actions/helpers'
import {
  adminGetSemesters,
  adminCreateSemester,
  adminUpdateSemester,
  adminDeleteSemester
} from '../../../../actions/'
import { messageTypes } from '../../../../actions/messages'
import SemesterModal from './modal'

class Semester extends Component {
  state = {
    data: []
  }

  componentDidMount () {
    this._isMounted = true
    this.getAllData()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  updateRecord = (id, data) => {
    const { language } = this.props
    this.setState({ loading: true })
    adminUpdateSemester(id, data, getCookie('_token')).then((json) => {
      this.setState({ loading: false })
      if (json.status === 'ok') {
        this.getAllData()
        this.setState({
          dataId: null
        })
        openNotificationPopup(
          messageTypes[language].success,
          json.response[language],
          'smile'
        )
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
      }
    })
  }

  addRecord = (data) => {
    const { language } = this.props
    this.setState({ loading: true })
    adminCreateSemester(data, getCookie('_token')).then((json) => {
      this.setState({ loading: false })
      if (json.status === 'ok') {
        this.getAllData()
        this.showAddRecordModal(null)
        openNotificationPopup(
          messageTypes[language].success,
          json.response[language],
          'smile'
        )
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
      }
    })
  }

  getAllData = () => {
    const { language } = this.props

    adminGetSemesters(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            data: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  deleteRecord = (id) => {
    const { language } = this.props

    confirm('delete_semester_question', language, () => {
      adminDeleteSemester(id, getCookie('_token')).then((json) => {
        if (this._isMounted) {
          if (json.status === 'ok') {
            this.setState({ data: deleteElementWithIdFromArr(id, this.state.data) })
            openNotificationPopup(
              messageTypes[language].success,
              json.response[language],
              'smile'
            )
          } else if (json.status === 'err') {
            openNotificationPopup(messageTypes[this.props.language].oops, json.response[language], 'frown')
          }
        }
      })
    })
  }

  setDataId = (dataId) => { this.setState({ dataId }) }
  showAddRecordModal = (isNewRecord) => { this.setState({ isNewRecord }) }

  returnWithKey (data, lang) {
    const retArr = []
    data.forEach((val, i) => {
      const obj = {}
      obj.key = i
      obj._id = val._id
      obj.name = val.name && val.name[lang]

      retArr.push(obj)
    })
    return retArr
  }

  render () {
    const { data, dataId, isNewRecord } = this.state
    const { language, user } = this.props

    return (
      <>
        {user?.role === 0 && (
          <AddRecordButton
            language={language}
            showAddRecordModal={this.showAddRecordModal}
            title={messageTypes[language].new_semester}
          />
        )}
        <SemesterModal
          language={language}
          data={data}
          dataId={dataId}
          setDataId={this.setDataId}
          isNewRecord={isNewRecord}
          loading={this.state.loading}
          addRecord={this.addRecord}
          updateRecord={this.updateRecord}
          showAddRecordModal={this.showAddRecordModal}
        />
        <StyledSemesterTable
          language={language}
          data={this.returnWithKey(data, language)}
          setDataId={this.setDataId}
          deleteRecord={this.deleteRecord}
          user={user}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language,
  user: state.hdStore.user
})

const SemesterContainer = connect(
  mapStateToProps
)(Semester)

export default SemesterContainer
