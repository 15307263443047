import React from 'react'
import { messageTypes } from '../../../../actions/messages'
import {
  Form, Input, Button, Divider
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

class StyledSemesterForm extends React.Component {
  formRef = React.createRef()

  handleSubmit = (values) => {
    const { data, isNewRecord, addRecord, updateRecord } = this.props
    const obj = {}
    obj.name = { pl: values.name_pl, en: values.name_en }
    obj.src_version = data.name
    obj.stack_type = this.props.stackType

    if (isNewRecord) {
      addRecord(obj)
    } else { updateRecord(data._id, obj) }
  }

  setDefaultValues () {
    const { data, isNewRecord } = this.props

    if (data && Object.keys(data).length > 0 && !isNewRecord) {
      const fieldsVal = {
        name_pl: data.name.pl,
        name_en: data.name.en
      }
      this.formRef.current.setFieldsValue(fieldsVal)
    } else {
      this.formRef.current.setFieldsValue({
        name_pl: '',
        name_en: ''
      })
    }
  }

  componentDidMount () {
    this.setDefaultValues()
  }

  componentDidUpdate (prevProps, prevState) {
    const { data, isNewRecord } = this.props

    if (data._id !== prevProps.data._id || isNewRecord !== prevProps.isNewRecord) {
      this.setDefaultValues()
    }
  }

  render () {
    const { language, loading } = this.props

    return (
      <Form onFinish={this.handleSubmit} layout='horizontal' ref={this.formRef}>
        <Form.Item
          name='name_pl'
          label={messageTypes[language].name_pl}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name='name_en'
          label={messageTypes[language].name_en}
          {...formItemLayout}
        >
          <Input />
        </Form.Item>

        <Form.Item className='form-footer'>
          <Divider />
          <Button htmlType='submit' disabled={loading}>{loading ? <LoadingOutlined /> : ''} {messageTypes[language].submit}</Button>
          <Button disabled={loading} onClick={() => { this.props.onCancel() }} className='cancel'>{messageTypes[language].cancel}</Button>
        </Form.Item>
      </Form>
    )
  }
}

export default StyledSemesterForm
