import React from 'react'
import { Modal } from 'antd'
import { messageTypes } from '../../../../actions/messages'
import { takeElementWithIdFromArr, getCookie, openNotificationPopup } from '../../../../actions/helpers'
import StyledChallengeCategoryForm from '../../../../styledComponents/sections/settings/challengeCategories/form'
import {
  stackTypesOverlordGet,
  adminGetContentPacksForStackType,
  adminGetMapIcons
} from '../../../../actions/'

class ChallengeCategoryModalSettings extends React.Component {
  state = {
    singleData: {},
    contentPackVersions: [],
    overlordStackTypes: [],
    icons: []
  }

  handleCancel = () => {
    const { setDataId, showAddRecordModal } = this.props
    setDataId(null)
    showAddRecordModal(false)
    this.setState({
      contentPackVersions: []
    })
  }

  getContentPackVersions = (stackType) => {
    const { language } = this.props

    adminGetContentPacksForStackType(stackType, getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            contentPackVersions: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  componentDidUpdate (prevProps, prevState) {
    const { dataId, data } = this.props
    if (prevProps.dataId !== dataId) {
      const singleData = takeElementWithIdFromArr(dataId, data)
      this.setState({ singleData })
      if (singleData) {
        this.getContentPackVersions(singleData.stack_type)
        this.adminGetMapIcons(singleData.stack_type, singleData.content_pack_version)
      }
    }
  }

  componentDidMount () {
    this._isMounted = true
    this.getStackTypesFromOverlord()
  }

  componentWillUnmount () {
    this._isMounted = true
  }

  getStackTypesFromOverlord = () => {
    const { language } = this.props
    stackTypesOverlordGet(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            overlordStackTypes: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }

        this.setState({
          loading: false
        })
      }
    })
  }

  adminGetMapIcons = (stackType, contentPackVersion) => {
    const { language } = this.props

    adminGetMapIcons(stackType, contentPackVersion, getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          const icons = {}

          for (const icon of json.response) {
            for (const res of icon.resources) {
              if (res.active) {
                icons[icon.name] = {
                  image: '/images/mapicon/' + res._id + '.svg',
                  size: icon.size,
                  shape: icon.shape
                }
              }
            }
          }

          this.setState({
            icons
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  render () {
    const {
      language, dataId, isNewRecord, loading,
      updateRecord, addRecord
    } = this.props
    const { singleData } = this.state

    return (
      <div>
        <Modal
          maskClosable={false}
          width={1000}
          visible={!!(dataId || isNewRecord)}
          title={
            (isNewRecord && messageTypes[language].new_challenge_category) ||
            (
              singleData &&
              singleData.name &&
              singleData.name[language])
          }
          onCancel={this.handleCancel}
          footer={null}
        >
          <StyledChallengeCategoryForm
            isNewRecord={isNewRecord}
            data={singleData || {}}
            language={language}
            onCancel={this.handleCancel}
            updateRecord={updateRecord}
            addRecord={addRecord}
            loading={loading}
            contentPackVersions={this.state.contentPackVersions}
            getContentPackVersions={this.getContentPackVersions}
            overlordStackTypes={this.state.overlordStackTypes}
            adminGetMapIcons={this.adminGetMapIcons}
            icons={this.state.icons}
          />
        </Modal>
      </div>
    )
  }
}

export default ChallengeCategoryModalSettings
