import React from 'react'
import { Modal, Divider } from 'antd'
import { messageTypes } from '../../../../actions/messages'
import { takeElementWithIdFromArr, getCookie, openNotificationPopup } from '../../../../actions/helpers'
import { adminGetChallenge, challengeFileDownload, adminGetChallengeResources } from '../../../../actions/'
import dateformat from 'dateformat'
import PerfectScrollbar from 'react-perfect-scrollbar'
import ReactMarkdown from 'react-markdown'
import StyledFilesDownloader from '../../../../styledComponents/common/filesDownloader'

const FileDownload = require('js-file-download')

class ChallegeDetailsModalSettings extends React.Component {
  state = {
    challengeData: {},
    solves: [],
    solvesCount: 0,
    resources: []
  }

  handleCancel = () => {
    const { setShowDataId } = this.props
    setShowDataId(null)
  }

  componentDidUpdate (prevProps, prevState) {
    const { dataId, data, language } = this.props

    if (prevProps.dataId !== dataId) {
      const challengeData = takeElementWithIdFromArr(dataId, data)
      this.setState({ challengeData })

      if (challengeData) {
        adminGetChallenge(dataId, getCookie('_token')).then((json) => {
          if (json.status === 'ok') {
            this.setState({
              solves: json.response.solves,
              solvesCount: json.response.solves_count,
              uniqueFlags: json.response.unique_flags
            })
          } else if (json.status === 'err') {
            openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
          }
        })

        this.getChallengeResources(challengeData)
      }
    }
  }

  downloadChallengeFile (element, filename, language, fileEntity) {
    challengeFileDownload(element._id, filename, language, getCookie('_token')).then((response) => {
      // download file if get was successfull
      if (response.status === 200) {
        const file = fileEntity || element.files.find(x => x.path[language] === filename)

        FileDownload(response.data, file.label[language] + '.' + file.file_type)
      } else {
        openNotificationPopup(messageTypes[language].oops, messageTypes.universal.error, 'frown')
      }
    })
  }

  getChallengeResources (element) {
    adminGetChallengeResources(element._id, getCookie('_token')).then((json) => {
      if (json.status === 'ok') {
        this.setState({ resources: json.response })
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[this.props.language].oops, json.response[this.props.language], 'frown')
      }
    })
  }

  render () {
    const { language, dataId } = this.props
    const { challengeData, solves, solvesCount, uniqueFlags, resources } = this.state

    return (
      challengeData
        ? (
          <>
            <Modal
              visible={!!dataId}
              title={
                messageTypes[language].challenge_details + ': ' + (challengeData && challengeData.name && challengeData.name[language])
              }
              onCancel={this.handleCancel}
              footer={null}
              className='challenge-modal'
            >
              <div className='challenge-modal-body'>
                <PerfectScrollbar ref={(ref) => { this._scrollBarRef = ref }} style={{ padding: '24px' }}>
                  <div className='challenge-icon'>
                    <span>{challengeData.challenge_category?.name[language]}</span> <img src={'/images/icons/' + challengeData.level + 'stars_clean.svg'} alt={challengeData.level} />
                  </div>

                  <div className='challenge-description'>
                    <ReactMarkdown>
                      {challengeData.desc && challengeData.desc[language]}
                    </ReactMarkdown>

                    {!challengeData.flag_unique_per_user && <p>{messageTypes[language].flag}: {challengeData.flag}</p>}
                  </div>

                  {resources && resources.length > 0 &&
                    <div className='challenge-files'>
                      <StyledFilesDownloader
                        language={language}
                        element={{ _id: challengeData._id, files: resources }}
                        customLabel={messageTypes[language].files}
                        downloadFile={this.downloadChallengeFile}
                        modal
                        useFileId
                      />
                    </div>}

                  <Divider>
                    {messageTypes[language].solves} ({solvesCount})
                  </Divider>
                  <div>
                    {
                      solves && solves.length > 0 && solves.map((stack, i) => (
                        stack.items.length && (
                          <div key={i} className='admin-challenge-solve'>
                            <p>{stack.name}</p>
                            {stack.items.map((val, i) => (
                              <div key={i}>{val.username} ({dateformat(val.solve_date, 'yyyy-mm-dd HH:MM:ss')})<br /></div>
                            ))}
                          </div>
                        )
                      ))
                    }
                    {!solves || solves.length === 0 ? messageTypes[language].no_solves : ''}
                  </div>

                  {challengeData.flag_unique_per_user || challengeData.flag_unique_per_stack &&
                    <>
                      <Divider>
                        {challengeData.flag_unique_per_user ? messageTypes[language].flags_for_users : messageTypes[language].flags_for_stacks}
                      </Divider>
                      <div>
                        {
                          uniqueFlags && uniqueFlags.length > 0 && uniqueFlags.map((val, i) => (
                            <div key={i}>{val.flag} ({val.user.name})<br /></div>
                          ))
                        }
                        {!uniqueFlags || uniqueFlags.length === 0 ? messageTypes[language].no_flags : ''}
                      </div>
                    </>}
                </PerfectScrollbar>
              </div>
            </Modal>
          </>
          )
        : ''
    )
  }
}

export default ChallegeDetailsModalSettings
