import React from 'react'
import { Modal } from 'antd'
import { messageTypes } from '../../../../actions/messages'
import { takeElementWithIdFromArr } from '../../../../actions/helpers'
import StyledUserForm from '../../../../styledComponents/sections/settings/users/userForm'

class UsersModalSettings extends React.Component {
  state = {
    singleData: {}
  }

  handleCancel = () => {
    const { setDataId, showAddRecordModal } = this.props
    setDataId(null)
    showAddRecordModal(false)
  }

  componentDidUpdate (prevProps, prevState) {
    const { dataId, data } = this.props
    if (prevProps.dataId !== dataId) {
      const singleData = takeElementWithIdFromArr(dataId, data)
      this.setState({ singleData })
    }
  }

  render () {
    const {
      language, dataId, isNewRecord, loading,
      updateRecord, addRecord, stacks, isSuperAdmin
    } = this.props
    const { singleData } = this.state

    return (
      <div>
        <Modal
          visible={!!(dataId || isNewRecord)}
          title={
            (isNewRecord && messageTypes[language].new_user) ||
            (singleData && singleData.name)
          }
          onCancel={this.handleCancel}
          footer={null}
        >
          <StyledUserForm
            isNewRecord={isNewRecord}
            data={singleData || {}}
            stacks={stacks}
            language={language}
            onCancel={this.handleCancel}
            updateRecord={updateRecord}
            addRecord={addRecord}
            loading={loading}
            isSuperAdmin={isSuperAdmin}
            semestersClasses={this.props.semestersClasses}
          />
        </Modal>
      </div>
    )
  }
}

export default UsersModalSettings
