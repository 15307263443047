import React from 'react'
import { messageTypes } from '../../../../actions/messages'
import settings from '../../../../config/'
import ResourceFilesFormComponent from '../../../common/filesWithUploadForm'
import {
  Form, Select, Input, Button, Divider, Checkbox, InputNumber
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
const { Option } = Select
const { TextArea } = Input

class StyledScenariosForm extends ResourceFilesFormComponent {
  formRef = React.createRef()

  prepareFormValues (values) {
    return {
      name: { pl: values.name_pl, en: values.name_en },
      desc: { pl: values.desc_pl, en: values.desc_en },
      desc_long: { pl: values.desc_long_pl, en: values.desc_long_en },
      difficulty: values.difficulty,
      tags: (values.tags && values.tags.split(',')) || [],
      active: values.active,
      machines_by_name: values.machines_by_name,
      order: values.order,
      s_type: values.s_type,
      roles: values.roles,
      // stack_types: values.stack_types,
      recruitment: values.recruitment,
      demo: values.demo,
      blockable: values.blockable,
      stack_type: values.stack_type,
      content_pack_version: values.content_pack_version
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { data, isNewRecord, contentPackVersions } = this.props

    if (data._id !== prevProps.data._id || isNewRecord !== prevProps.isNewRecord) {
      this.setDefaultValues()
    }

    if (data._id !== prevProps.data._id || data === null) {
      this.setState({ fromDisabled: false })
    }

    if (contentPackVersions?.length > prevProps.contentPackVersions?.length) {
      const fromDisabled = contentPackVersions?.find(x => x.name === data.content_pack_version)?.status === 'final'

      this.setState({ fromDisabled })
    }
  }

  prepareFormExistingValues (data) {
    return {
      name_pl: data.name.pl,
      name_en: data.name.en,
      desc_pl: data.desc?.pl,
      desc_en: data.desc?.en,
      desc_long_pl: data.desc_long?.pl,
      desc_long_en: data.desc_long?.en,
      difficulty: data.difficulty,
      tags: data.tags.join(','),
      active: data.active,
      machines_by_name: data.machines_by_name,
      order: data.order,
      s_type: data.s_type,
      roles: data.roles,
      // stack_types: data.stack_types,
      recruitment: data.recruitment,
      demo: data.demo,
      stack_type: data.stack_type,
      content_pack_version: data.content_pack_version
    }
  }

  prepareFormDefaultValues () {
    return {
      name_pl: '',
      name_en: '',
      desc_pl: '',
      desc_en: '',
      desc_long_pl: '',
      desc_long_en: '',
      difficulty: '',
      tags: '',
      active: false,
      machines_by_name: [],
      order: '',
      s_type: '',
      roles: [],
      stack_types: [],
      recruitment: false,
      demo: false,
      blockable: false,
      stack_type: undefined,
      content_pack_version: undefined
    }
  }

  addFile = (challengeId, callAfterCreate) => {
    const { addFile } = this.props
    addFile && addFile(challengeId).then(result => callAfterCreate(result))
  }

  removeSingleFile = (challengeId, lang, callAfterCreate) => {
    const { removeSingleFile } = this.props
    removeSingleFile && removeSingleFile(challengeId, lang).then(result => callAfterCreate && callAfterCreate(result))
  }

  removeResource = (challengeId, callAfterCreate) => {
    const { removeResource } = this.props
    removeResource && removeResource(challengeId).then(result => callAfterCreate && callAfterCreate(result))
  }

  handleStackTypeChange = (stackType) => {
    this.setState({ stackType })

    this.props.getContentPackVersions(stackType)
  }

  adminCopyResourceFileToLang = (id, lang, dstLang) => {
    this.props.adminCopyScenarioResourceFileToLang(id, lang, dstLang)
  }

  render () {
    const { language, data, loading, machines, isNewRecord, overlordStackTypes } = this.props
    const { fromDisabled, progress } = this.state

    const uploading = Object.keys(progress).some(x => progress[x] !== undefined)

    return (
      <Form
        onFinish={this.handleSubmit}
        layout='horizontal'
        ref={this.formRef}
      >
        <Form.Item
          name='name_pl'
          label={messageTypes[language].name_pl}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <Input disabled={fromDisabled} />
        </Form.Item>

        <Form.Item
          name='name_en'
          label={messageTypes[language].name_en}
          // rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <Input disabled={fromDisabled} />
        </Form.Item>

        <Form.Item
          name='desc_pl'
          label={messageTypes[language].desc_pl}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <TextArea disabled={fromDisabled} rows={4} />
        </Form.Item>

        <Form.Item
          name='desc_en'
          label={messageTypes[language].desc_en}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <TextArea disabled={fromDisabled} rows={4} />
        </Form.Item>

        <Form.Item
          name='desc_long_pl'
          label={messageTypes[language].desc_long_pl}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <TextArea disabled={fromDisabled} rows={6} />
        </Form.Item>

        <Form.Item
          name='desc_long_en'
          label={messageTypes[language].desc_long_en}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <TextArea disabled={fromDisabled} rows={6} />
        </Form.Item>

        <Form.Item
          name='difficulty'
          label={messageTypes[language].difficulty}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <Select disabled={fromDisabled}>
            <Option value={1}>1</Option>
            <Option value={2}>2</Option>
            <Option value={3}>3</Option>
            <Option value={4}>4</Option>
            <Option value={5}>5</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name='tags'
          label={messageTypes[language].tags}
          {...this.formItemLayout}
        >
          <Input placeholder={messageTypes[language].tags_split_by_comma} disabled={fromDisabled} />
        </Form.Item>

        {!isNewRecord && this.fileFieldsRender('scenarios', data.scenario_hd_id, 'scenarios', fromDisabled)}

        <Form.Item
          name='active'
          label={messageTypes[language].active}
          valuePropName='checked'
          {...this.formItemLayout}
        >
          <Checkbox defaultChecked={data.active || false} />
        </Form.Item>

        <Form.Item
          name='machines_by_name'
          label={messageTypes[language].machines}
          {...this.formItemLayout}
        >
          <Select mode='multiple' placeholder={messageTypes[language].machines} disabled={fromDisabled}>
            {machines && machines.map((vm, i) =>
              <Option key={i} value={vm.name.toLowerCase()}>{vm.name}</Option>)}
          </Select>
        </Form.Item>

        <Form.Item
          name='order'
          label={messageTypes[language].order}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <InputNumber disabled={fromDisabled} />
        </Form.Item>

        <Form.Item
          name='s_type'
          label={messageTypes[language].s_type}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <Select disabled={fromDisabled}>
            <Option value='presentation'>{messageTypes[language].scenario_type_presentation}</Option>
            <Option value='education'>{messageTypes[language].scenario_type_education}</Option>
            <Option value='recruit'>{messageTypes[language].scenario_type_recruit}</Option>
            <Option value='hardware'>{messageTypes[language].scenario_type_hardware}</Option>
            <Option value='blueteam'>{messageTypes[language].scenario_type_blueteam}</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name='roles'
          label={messageTypes[language].view_for_roles}
          {...this.formItemLayout}
        >
          <Select
            mode='multiple'
            disabled={fromDisabled}
            filterOption={(input, option) =>
              (option.children ?? '').toLowerCase().includes(input.toLowerCase())
            }
          >
            {
              settings.roles.length
                ? settings.roles.map((element, n) =>
                  <Option key={n} value={element.role}>{messageTypes[language]['role_' + element.name]}</Option>)
                : ''
            }
          </Select>
        </Form.Item>

        {/* <Form.Item
          name='stack_types'
          label={messageTypes[language].view_for_stack_types}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <Select mode='multiple' disabled={fromDisabled}>
            {this.props.overlordStackTypes && this.props.overlordStackTypes.length && this.props.overlordStackTypes.map((stackType, i) =>
              <Option key={i} value={stackType.name}>{stackType.name}</Option>)}
          </Select>
        </Form.Item> */}

        <Form.Item
          name='stack_type'
          label={messageTypes[language].stack_type}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <Select onChange={this.handleStackTypeChange} disabled={fromDisabled}>
            {overlordStackTypes && overlordStackTypes.length && overlordStackTypes.map((stackType, i) =>
              <Option key={i} value={stackType.name}>{stackType.name}</Option>)}
          </Select>
        </Form.Item>

        <Form.Item
          name='content_pack_version'
          label={messageTypes[language].content_pack_version}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <Select disabled={fromDisabled}>
            {this.props.contentPackVersions && this.props.contentPackVersions.length && this.props.contentPackVersions.filter(x => !fromDisabled ? x.status === 'draft' : true).map((version, i) =>
              <Option key={i} value={version.name}>{version.name}</Option>)}
          </Select>
        </Form.Item>

        <Form.Item
          name='recruitment'
          label={messageTypes[language].recruitment_scenario}
          valuePropName='checked'
          {...this.formItemLayout}
        >
          <Checkbox defaultChecked={data.recruitment || ''} />
        </Form.Item>

        <Form.Item
          name='demo'
          label={messageTypes[language].scenario_demo}
          valuePropName='checked'
          {...this.formItemLayout}
        >
          <Checkbox defaultChecked={data.demo || ''} />
        </Form.Item>

        <Form.Item
          name='blockable'
          label={messageTypes[language].scenario_reservable}
          valuePropName='checked'
          {...this.formItemLayout}
        >
          <Checkbox defaultChecked={data.blockable || ''} disabled={fromDisabled} />
        </Form.Item>

        <Form.Item className='form-footer'>
          <Divider />
          <Button disabled={uploading} htmlType='submit'>{loading ? <LoadingOutlined /> : ''} {messageTypes[language].submit}</Button>
          <Button disabled={uploading} onClick={() => { this.props.onCancel() }} className='cancel'>{messageTypes[language].cancel}</Button>
        </Form.Item>
      </Form>
    )
  }
}

export default StyledScenariosForm
