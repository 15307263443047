import React from 'react'
import { connect } from 'react-redux'
import { /* setCurrentStackMachines, */ setStack, setStackActionStatus, setStackPrev } from '../../store/actions'
import StyledStackSwitchDropdown from '../../styledComponents/common/stackSwitchDropdown'
import StackSwitchComponent from './stackSwitchComponent'
import { withRouter } from 'react-router-dom'

class StackSwitchDropdown extends StackSwitchComponent {
  // this is required for StackSwitchComponent
  redirect = (path) => {
    this.props.history.push(path)
  }

  render () {
    const { stack, language, userStacks, socketId, setStackActionStatus } = this.props
    const { switching, switching_to: switchingTo } = this.state

    return (
      <StyledStackSwitchDropdown
        stacks={userStacks}
        switching={switching}
        switchingTo={switchingTo}
        currentStackId={stack && typeof stack === 'object' && stack._id}
        language={language}
        switchStack={this.switchStack}
        socketId={socketId}
        setStackActionStatus={setStackActionStatus}
        redirect={this.redirect}
      />
    )
  }
}

const mapStateToProps = state => ({
  stack: state.hdStore.stack,
  userStacks: state.hdStore.user_stacks,
  socketId: state.hdStore.socketId
})

const mapDispatchToProps = {
  // setCurrentStackMachines,
  setStack,
  setStackPrev,
  setStackActionStatus
}

const StackSwitchDropdownContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StackSwitchDropdown)

export default withRouter(StackSwitchDropdownContainer)
