import React from 'react'
import { messageTypes } from '../../../../actions/messages'
import {
  Form, Select, Input, InputNumber, Popconfirm, Card, Col, Row, Button, Space, Divider, Empty
} from 'antd'
import { attributeGroups, networkColors, groupsBlacklisted } from './mapDraw'
import { PlusOutlined, MinusCircleOutlined, EditOutlined } from '@ant-design/icons'

const { Option } = Select

const formItemLayout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 }
}

const nodeTypes = [
  'vm',
  'map_object',
  'challenge',
  'vpn',
  'network',
  'network_header',
  'scenario'
]

const nodeStyles = [
  'node_label_gray',
  'node_label_red',
  'node_label_green',
  'node_label_yellow',
  'node_label_orange'
]

class StyledMapElementForm extends React.Component {
  formRef = React.createRef()
  state = {
    type: undefined,
    elementType: 'node'
  }

  handleSubmit = (values) => {
    const { data, updateElement } = this.props

    // remove empty attributes
    values.attributes = values.attributes?.filter(x => !(x.position === undefined && x.group === undefined && x.x_offset === undefined))

    updateElement(data._id, values)
  }

  setDefaultValues = () => {
    const { elementType, language } = this.props
    const data = this.props.data?.entity

    this.setState({ type: data?.type, elementType }, () => {
	    if(data) {
      const fieldsVal = {
        // label: data.challenge?.name && data.challenge?.name[language] === data.label ? '' : data.label,
        label: data.label,
        group: data.group,
        id: data.id,
        x: data.x,
        y: data.y,
        height: data.height,
        width: data.width,
        edge_width: data.edge_width,
        roundness: data.roundness,
        title: data.title,
        title_pl: data.title_pl,
        title_en: data.title_en,
        label_pl: data.label_pl,
        label_en: data.label_en,
        // label_lng_en: data.label_lng?.en,
        subtitle: data.subtitle,
        subtitle_pl: data.subtitle_pl,
        subtitle_en: data.subtitle_en,
        subtitle2: data.subtitle2,
        position: data.position,
        ip: data.ip,
        ip2: data.ip2,
        network: data.network,
        network2: data.network2,
        ip_style: data.ip_style,
        ip2_style: data.ip2_style,
        ip_public: data.ip_public,
        network_public: data.network_public,
        domain: data.domain,
        dns: data.dns,
        dns_public: data.dns_public,
        sublabel: data.sublabel,
        sublabel_pl: data.sublabel_pl,
        sublabel_en: data.sublabel_en,
        overlabel: data.overlabel,
        overlabel_pl: data.overlabel_pl,
        overlabel_en: data.overlabel_en,
        type: data.type,
        flags: data.flags,
        attributes: data.attributes,
        color: data.color,
        rorder: data.rorder,
        challenge: data.challenge?._id,
        scenario: data.scenario?._id
      }
      this.formRef.current.setFieldsValue(fieldsVal)
	    }
    })
  }

  componentDidMount () {
    this.setDefaultValues()
  }

  componentDidUpdate (prevProps, prevState) {
    const { data } = this.props

    if (data?.entity?._id !== prevProps.data?.entity?._id || this.props.updated !== prevProps.updated) {
      this.setDefaultValues()
    }
  }

  handleAttributeFocus = (name) => {
    this.setState({ attributeFocus: name })
  }

  handleAttributeFocusWithValidation = (name) => {
    this.formRef.current.validateFields()
      .then((values) => {
        this.handleAttributeFocus(name)
      })
      .catch((errorInfo) => {
      })
  }

  handleTypeChange = (value) => {
    this.setState({ type: value })

    if (value === 'challenge') {
      this.formRef.current.setFieldsValue({ label: '' })
    } else if (value) {
      if (this.formRef.current?.getFieldValue('label') === '') {
        this.formRef.current.setFieldsValue({ label: 'nowy' })
      }
    }

    if (value === 'network') {
      this.formRef.current.setFieldsValue({ group: 'network' })

      if (!this.formRef.current.getFieldValue('height') && !this.formRef.current.getFieldValue('width')) {
        this.formRef.current.setFieldsValue({ height: 250, width: 250 })
      }
    } else if (value === 'network_header') {
      this.formRef.current.setFieldsValue({ group: 'networkHeader' })

      if (!this.formRef.current.getFieldValue('title')) {
        this.formRef.current.setFieldsValue({ title: 'Network header' })
      }
    } else if (value === 'vpn') {
      this.formRef.current.setFieldsValue({ group: 'vpn' })
    }

    this.handleSubmit(this.formRef.current?.getFieldsValue())
  }

  handleFormChange = (values) => {
    this.formRef.current.submit()
  }

  handleOnFinish = (values) => {
    this.props.setSaveBlock(false)
    this.handleSubmit(this.formRef.current?.getFieldsValue())
  }

  handleOnFinishFailed = (values) => {
    if (values.errorFields.length === 0 && values.outOfDate) {
      this.handleSubmit(this.formRef.current?.getFieldsValue())
      this.props.setSaveBlock(false)
    } else {
      this.props.setSaveBlock(true)
    }
  }

  getAttribute = (name) => {
    return this.formRef.current?.getFieldValue('attributes')[name]
  }

  render () {
    const { language, map, formDisabled } = this.props
    let groupsToSet

    const elementType = this.props.data?.type

    if (elementType === 'node') {
      groupsToSet = { ...groupsBlacklisted, ...(this.props.iconsData || {}) }
    } else {
      groupsToSet = ['curve_dashed', 'curve_dotted', 'curve_arrow', 'dashed', 'dotted']
    }

    const popconfirmItemDeleteProps = {
      okText: messageTypes[language].yes,
      cancelText: messageTypes[language].cancel,
      placement: 'left'
    }

    return (
      <Form
        onValuesChange={this.handleFormChange}
        layout='horizontal'
        ref={this.formRef}
        onFinish={this.handleOnFinish}
        onFinishFailed={this.handleOnFinishFailed}
      >
        <Form.Item
          name='id'
          label={messageTypes[language].map_id}
          rules={[
            { required: true, message: messageTypes[language].field_required },
            {
              validator: (rule, value) => {
                const search = map.nodesData.filter(x => x.id === value && x._id !== this.props.data.entity._id)
                // if node with same id was not found resolve this validator
                if (search.length === 0) {
                  return Promise.resolve()
                }
                return Promise.reject(messageTypes[language].map_node_already_exists)
              }
            }]}
          {...formItemLayout}
        >
          <Input disabled={formDisabled} />
        </Form.Item>

        {elementType === 'node' &&
          <Form.Item
            name='type'
            label={messageTypes[language].map_type}
            rules={[{ required: true, message: messageTypes[language].field_required }]}
            {...formItemLayout}
          >
            <Select onChange={this.handleTypeChange} disabled={formDisabled}>
              {
                nodeTypes.map((type, n) =>
                  <Option key={n} value={type}>{type}</Option>
                )
              }
            </Select>
          </Form.Item>}

        <Form.Item
          name='title'
          label={messageTypes[language].map_title}
          rules={elementType === 'node' && ['network_header'].includes(this.state.type) ? [{ required: true, message: messageTypes[language].field_required }] : []}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['network_header'].includes(this.state.type))}
        >
          <Input disabled={formDisabled} />
        </Form.Item>

        <Form.Item
          name='title_pl'
          label={messageTypes[language].map_title_pl}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['network_header'].includes(this.state.type))}
        >
          <Input disabled={formDisabled} />
        </Form.Item>

        <Form.Item
          name='title_en'
          label={messageTypes[language].map_title_en}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['network_header'].includes(this.state.type))}
        >
          <Input disabled={formDisabled} />
        </Form.Item>

        <Form.Item
          name='subtitle'
          label={messageTypes[language].map_subtitle}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['network_header'].includes(this.state.type))}
        >
          <Input disabled={formDisabled} />
        </Form.Item>

        <Form.Item
          name='subtitle_pl'
          label={messageTypes[language].map_subtitle_pl}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['network_header'].includes(this.state.type))}
        >
          <Input disabled={formDisabled} />
        </Form.Item>

        <Form.Item
          name='subtitle_en'
          label={messageTypes[language].map_subtitle_en}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['network_header'].includes(this.state.type))}
        >
          <Input disabled={formDisabled} />
        </Form.Item>

        <Form.Item
          name='subtitle2'
          label={messageTypes[language].map_subtitle2}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['network_header'].includes(this.state.type))}
        >
          <Input disabled={formDisabled} />
        </Form.Item>

        <Form.Item
          name='label'
          label={messageTypes[language].map_label}
          rules={elementType === 'node' && this.state.type !== undefined && !['challenge', 'network_header', 'scenario'].includes(this.state.type) ? [{ required: true, message: messageTypes[language].field_required }] : []}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['vm', 'vpn', 'map_object', 'challenge', 'scenario'].includes(this.state.type))}
        >
          <Input disabled={formDisabled} />
        </Form.Item>

        <Form.Item
          name='label_pl'
          label={messageTypes[language].map_label_pl}
          // rules={elementType === 'node' && this.state.type !== undefined && !['challenge', 'network_header'].includes(this.state.type) ? [{ required: true, message: messageTypes[language].field_required }] : []}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['vm', 'vpn', 'map_object', 'challenge', 'scenario'].includes(this.state.type))}
        >
          <Input disabled={formDisabled} />
        </Form.Item>

        <Form.Item
          name='label_en'
          label={messageTypes[language].map_label_en}
          // rules={elementType === 'node' && this.state.type !== undefined && !['challenge', 'network_header'].includes(this.state.type) ? [{ required: true, message: messageTypes[language].field_required }] : []}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['vm', 'vpn', 'map_object', 'challenge', 'scenario'].includes(this.state.type))}
        >
          <Input disabled={formDisabled} />
        </Form.Item>

        <Form.Item
          name='sublabel'
          label={messageTypes[language].map_sublabel}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['vm', 'map_object', 'challenge', 'scenario'].includes(this.state.type))}
        >
          <Input disabled={formDisabled} />
        </Form.Item>

        <Form.Item
          name='sublabel_pl'
          label={messageTypes[language].map_sublabel_pl}
          // rules={elementType === 'node' && this.state.type !== undefined && !['challenge', 'network_header'].includes(this.state.type) ? [{ required: true, message: messageTypes[language].field_required }] : []}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['vm', 'vpn', 'map_object', 'challenge', 'scenario'].includes(this.state.type))}
        >
          <Input disabled={formDisabled} />
        </Form.Item>

        <Form.Item
          name='sublabel_en'
          label={messageTypes[language].map_sublabel_en}
          // rules={elementType === 'node' && this.state.type !== undefined && !['challenge', 'network_header'].includes(this.state.type) ? [{ required: true, message: messageTypes[language].field_required }] : []}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['vm', 'vpn', 'map_object', 'challenge', 'scenario'].includes(this.state.type))}
        >
          <Input disabled={formDisabled} />
        </Form.Item>

        <Form.Item
          name='overlabel'
          label={messageTypes[language].map_overlabel}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['vm', 'map_object', 'challenge', 'scenario'].includes(this.state.type))}
        >
          <Input disabled={formDisabled} />
        </Form.Item>

        <Form.Item
          name='overlabel_pl'
          label={messageTypes[language].map_overlabel_pl}
          // rules={elementType === 'node' && this.state.type !== undefined && !['challenge', 'network_header'].includes(this.state.type) ? [{ required: true, message: messageTypes[language].field_required }] : []}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['vm', 'vpn', 'map_object', 'challenge', 'scenario'].includes(this.state.type))}
        >
          <Input disabled={formDisabled} />
        </Form.Item>

        <Form.Item
          name='overlabel_en'
          label={messageTypes[language].map_overlabel_en}
          // rules={elementType === 'node' && this.state.type !== undefined && !['challenge', 'network_header'].includes(this.state.type) ? [{ required: true, message: messageTypes[language].field_required }] : []}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['vm', 'vpn', 'map_object', 'challenge', 'scenario'].includes(this.state.type))}
        >
          <Input disabled={formDisabled} />
        </Form.Item>

        {elementType === 'node' &&
          <Form.Item
            name='x'
            label={messageTypes[language].map_x}
            rules={[{ required: true, message: messageTypes[language].field_required }]}
            {...formItemLayout}
          >
            <InputNumber disabled={formDisabled} />
          </Form.Item>}

        {elementType === 'node' &&
          <Form.Item
            name='y'
            label={messageTypes[language].map_y}
            rules={[{ required: true, message: messageTypes[language].field_required }]}
            {...formItemLayout}
          >
            <InputNumber disabled={formDisabled} />
          </Form.Item>}

        <Form.Item
          name='width'
          label={messageTypes[language].map_width}
          rules={elementType === 'node' && ['network'].includes(this.state.type) ? [{ required: true, message: messageTypes[language].field_required }] : []}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['network'].includes(this.state.type))}
        >
          <InputNumber disabled={formDisabled} />
        </Form.Item>

        <Form.Item
          name='edge_width'
          label={messageTypes[language].map_edge_width}
          {...formItemLayout}
          hidden={!(elementType === 'edge')}
        >
          <InputNumber disabled={formDisabled} min={1} max={10} />
        </Form.Item>

        <Form.Item
          name='height'
          label={messageTypes[language].map_height}
          rules={elementType === 'node' && ['network'].includes(this.state.type) ? [{ required: true, message: messageTypes[language].field_required }] : []}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['network'].includes(this.state.type))}
        >
          <InputNumber disabled={formDisabled} />
        </Form.Item>

        <Form.Item
          name='group'
          label={elementType === 'edge' ? messageTypes[language].map_line_style : messageTypes[language].map_icon}
          rules={[elementType === 'node' ? { required: true, message: messageTypes[language].field_required } : {}]}
          {...formItemLayout}
          hidden={!(elementType === 'edge' || ['vm', 'map_object', 'scenario'].includes(this.state.type))}
        >
          <Select allowClear disabled={formDisabled}>
            {
              elementType === 'node'
                ? (
                    Object.keys(groupsToSet).map((group, n) =>
                      <Option key={n} value={group}>
                        {groupsBlacklisted[group]?.image && <><img style={{ height: '22px' }} src={groupsBlacklisted[group]?.image} alt={group} /> </>}
                        {!groupsBlacklisted[group]?.image && groupsToSet[group].image && <><img style={{ height: '22px' }} src={groupsToSet[group].image} alt={group} /> </>}
                        {group}
                      </Option>)
                  )
                : groupsToSet.map((group, n) =>
                  <Option key={n} value={group}>
                    {group}
                  </Option>)
            }
          </Select>
        </Form.Item>

        <Form.Item
          name='color'
          label={messageTypes[language].map_color}
          {...formItemLayout}
          hidden={!(elementType === 'edge' || ['network', 'network_header'].includes(this.state.type))}
        >
          <Select allowClear disabled={formDisabled}>
            {
              networkColors.map((color, n) =>
                <Option key={n} value={color}>
                  <span style={{ backgroundColor: color }} className='map-network-color-optionx'>&nbsp;&nbsp;</span> {color}
                </Option>)
            }
          </Select>
        </Form.Item>

        <Form.Item
          name='ip'
          label={messageTypes[language].map_ip}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['vpn', 'vm', 'map_object'].includes(this.state.type))}
        >
          <Input disabled={formDisabled} />
        </Form.Item>

        <Form.Item
          name='network'
          label={messageTypes[language].map_network}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['vpn', 'vm', 'map_object'].includes(this.state.type))}
        >
          <Input disabled={formDisabled} />
        </Form.Item>

        <Form.Item
          name='ip_style'
          label={messageTypes[language].map_ip_style}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['vm', 'map_object'].includes(this.state.type))}
        >
          <Select allowClear disabled={formDisabled}>
            {
              nodeStyles.map((group, n) =>
                <Option key={n} value={group}>
                  {group}
                </Option>
              )
            }
          </Select>
        </Form.Item>

        <Form.Item
          name='ip2'
          label={messageTypes[language].map_ip2}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['vm', 'map_object'].includes(this.state.type))}
        >
          <Input disabled={formDisabled} />
        </Form.Item>

        <Form.Item
          name='network2'
          label={messageTypes[language].map_network2}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['vm', 'map_object'].includes(this.state.type))}
        >
          <Input disabled={formDisabled} />
        </Form.Item>

        <Form.Item
          name='ip2_style'
          label={messageTypes[language].map_ip2_style}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['vm', 'map_object'].includes(this.state.type))}
        >
          <Select allowClear disabled={formDisabled}>
            {
              nodeStyles.map((group, n) =>
                <Option key={n} value={group}>
                  {group}
                </Option>
              )
            }
          </Select>
        </Form.Item>

        <Form.Item
          name='ip_public'
          label={messageTypes[language].map_ip_public}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['vm', 'map_object'].includes(this.state.type))}
        >
          <Input disabled={formDisabled} />
        </Form.Item>

        <Form.Item
          name='network_public'
          label={messageTypes[language].map_network_public}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['vm', 'map_object'].includes(this.state.type))}
        >
          <Input disabled={formDisabled} />
        </Form.Item>

        <Form.Item
          name='dns'
          label={messageTypes[language].map_dns}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['vm', 'map_object'].includes(this.state.type))}
        >
          <Input disabled={formDisabled} />
        </Form.Item>

        <Form.Item
          name='dns_public'
          label={messageTypes[language].map_dns_public}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['vm', 'map_object'].includes(this.state.type))}
        >
          <Input disabled={formDisabled} />
        </Form.Item>

        <Form.Item
          name='domain'
          label={messageTypes[language].map_domain}
          {...formItemLayout}
          hidden={!(elementType === 'node' && ['vm', 'map_object'].includes(this.state.type))}
        >
          <Input disabled={formDisabled} />
        </Form.Item>

        {elementType === 'edge' &&
          <Form.Item
            name='roundness'
            label={messageTypes[language].map_roundness}
            {...formItemLayout}
          >
            <InputNumber disabled={formDisabled} />
          </Form.Item>}

        <Form.Item
          name='position'
          label={messageTypes[language].map_network_edge_position}
          {...formItemLayout}
          hidden={!['network'].includes(this.state.type)}
        >
          <Select allowClear disabled={formDisabled}>
            {
              ['bottom', 'top', 'left', 'right', 'left_right', 'all'].map((group, n) =>
                <Option key={n} value={group}>
                  {groupsBlacklisted[group]?.image && <><img style={{ height: '22px' }} src={groupsBlacklisted[group]?.image} alt={group} /> </>}
                  {group}
                </Option>
              )
            }
          </Select>
        </Form.Item>

        <Form.Item
          name='rorder'
          label={messageTypes[language].order}
          {...formItemLayout}
          hidden={elementType === 'edge'}
        >
          <InputNumber disabled={formDisabled} />
        </Form.Item>

        <Form.Item
          name='flags'
          label={messageTypes[language].attached_flags}
          {...formItemLayout}
          hidden={!['map_object'].includes(this.state.type)}
        >
          <Select mode='multiple' allowClear disabled={formDisabled}>
            {
              this.props.flags?.map((flag, n) =>
                <Option key={n} value={flag._id} title={flag.name + ': ' + flag.value}>
                  {flag.name}: {flag.value}
                </Option>
              )
            }
          </Select>
        </Form.Item>

        <Form.Item
          name='challenge'
          label={messageTypes[language].attached_challenge}
          rules={['challenge'].includes(this.state.type) ? [{ required: true, message: messageTypes[language].field_required }] : []}
          {...formItemLayout}
          hidden={!['challenge'].includes(this.state.type)}
        >
          <Select
            allowClear
            showSearch
            optionFilterProp='children'
            disabled={formDisabled}
            filterOption={(input, option) =>
              (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
          >
            {
              this.props.challenges?.map((challenge, n) =>
                <Option key={n} value={challenge._id}>
                  {challenge.name[language] + ' (' + challenge.rorder + ')'}
                </Option>
              )
            }
          </Select>
        </Form.Item>

        <Form.Item
          name='scenario'
          label={messageTypes[language].scenario}
          rules={['scenario'].includes(this.state.type) ? [{ required: true, message: messageTypes[language].field_required }] : []}
          {...formItemLayout}
          hidden={!['scenario'].includes(this.state.type)}
        >
          <Select
            allowClear
            showSearch
            optionFilterProp='children'
            disabled={formDisabled}
            filterOption={(input, option) =>
              (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
          >
            {
              this.props.scenarios?.map((scenario, n) =>
                <Option key={n} value={scenario._id}>
                  {scenario.name[language]}
                </Option>
              )
            }
          </Select>
        </Form.Item>

        {elementType === 'node' && this.state.type !== 'challenge' &&
          <Form.List
            name='attributes'
          >
            {(fields, { add, remove }, { errors }) => (
              <Card
                title={messageTypes[language].attributes}
                className='map-element-attributes'
                extra={
                  <Button
                    type='secondary'
                    disabled={formDisabled}
                    onClick={() => { add({ position: '', group: '', x_offset: 0 }); this.handleAttributeFocus(fields?.length) }}
                    icon={<PlusOutlined />}
                  />
                }
              >
                {(!fields.length && <Empty description={messageTypes[language].no_attributes} />) || fields.map(({ key, name, ...restField }) => (
                  <React.Fragment key={key}>
                    {this.state.attributeFocus === name && name !== 0 && <Divider />}

                    <Form.Item
                      {...restField}
                      name={[name, 'position']}
                      label={messageTypes[language].map_position}
                      validateTrigger={['onChange']}
                      rules={[{ required: true, message: messageTypes[language].field_required }]}
                      hidden={!(this.state.attributeFocus === name)}
                    >
                      <Select>
                        {
                          ['top', 'top_right', 'bottom_right'].map((ing, n) =>
                            <Option key={n} value={ing}>{ing}</Option>
                          )
                        }
                      </Select>
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, 'group']}
                      validateTrigger={['onChange']}
                      label={messageTypes[language].map_icon}
                      style={{ minWidth: '70px' }}
                      rules={[{ required: true, message: messageTypes[language].field_required }]}
                      hidden={!(this.state.attributeFocus === name)}
                    >
                      <Select>
                        {
                          Object.keys(attributeGroups).map((attrGroup, n) =>
                            <Option key={n} value={attrGroup}>
                              {attributeGroups[attrGroup].image && <><img style={{ height: '30px' }} src={attributeGroups[attrGroup].image} alt={attrGroup} /> {attrGroup}</>}
                            </Option>
                          )
                        }
                      </Select>
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, 'x_offset']}
                      validateTrigger={['onChange']}
                      label={messageTypes[language].map_x_offset}
                      style={{ minWidth: '70px' }}
                      rules={[{ required: true, message: messageTypes[language].field_required }]}
                      hidden={!(this.state.attributeFocus === name)}
                    >
                      <InputNumber />
                    </Form.Item>

                    {!(this.state.attributeFocus === name) &&
                      <Row gutter={[0, 20]}>
                        <Col xs={17}>
                          <img style={{ height: '30px' }} src={attributeGroups[this.getAttribute(name).group]?.image} alt={this.getAttribute(name).group} /> {this.getAttribute(name).group} ({this.getAttribute(name).position})
                        </Col>
                        <Col xs={7}>
                          <Space>
                            {!(this.state.attributeFocus === name) &&
                              <Button disabled={formDisabled} icon={<EditOutlined />} title={messageTypes[language].edit} onClick={() => this.handleAttributeFocusWithValidation(name)} />}

                            <Popconfirm
                              title={messageTypes[language].delete_attribute_question}
                              onConfirm={() => remove(name)}
                              {...popconfirmItemDeleteProps}
                              disabled={formDisabled}
                            >
                              <Button disabled={formDisabled} icon={<MinusCircleOutlined />} title={messageTypes[language].delete} />
                            </Popconfirm>
                          </Space>
                        </Col>
                      </Row>}

                    {this.state.attributeFocus === name &&
                      <Space>
                        <Button onClick={() => { this.handleAttributeFocusWithValidation(undefined) }}>{messageTypes[language].save}</Button>
                        <Popconfirm
                          title={messageTypes[language].delete_attribute_question}
                          onConfirm={() => remove(name)}
                          {...popconfirmItemDeleteProps}
                          disabled={formDisabled}
                        >
                          <Button>{messageTypes[language].delete}</Button>
                        </Popconfirm>
                      </Space>}

                    {this.state.attributeFocus === name && name !== (fields.length - 1) && <Divider />}
                  </React.Fragment>
                ))}

                <Form.ErrorList errors={errors} />
              </Card>
            )}
          </Form.List>}
      </Form>
    )
  }
}

export default StyledMapElementForm
