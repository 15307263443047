import React, { Component } from 'react'
import MachineCard from '../../../components/sections/machines/machinesCard'
import { Row, Col } from 'antd'

class StyledMachines extends Component {
  checkDescIsForVpn = (element) => {
    const vpn = this.props.vpns && this.props.vpns.find(x => (x.vpn_vm_name && x.vpn_vm_name.toLowerCase()) === element.name.pl.toLowerCase())
    return (vpn ? vpn.desc[this.props.language] : null)
  }

  render () {
    const { machines, getAllMachines, setAllowStateStatus, allowStateStatus, stack } = this.props

    return (
      <div className={`machines ${machines.length ? 'fade-in' : 'fade-out'}`}>
        <Row gutter={[35, 35]}>
          {
            machines.length
              ? machines.map((element, n) =>
                <Col
                  key={n}
                  style={{ marginBottom: 8 }}
                  xxl={6} xl={12} lg={12} md={24} sm={24} xs={24}
                >
                  <MachineCard
                    element={element}
                    status={element.resources.status} // this is required to properly detect status change
                    machines={machines}
                    desc={this.checkDescIsForVpn(element)}
                    getAllMachines={getAllMachines}
                    setAllowStateStatus={setAllowStateStatus}
                    allowStateStatus={allowStateStatus}
                    onHandleConsole={this.props.onHandleConsole}
                    stack={stack}
                  />
                </Col>
              )
              : ''
          }
        </Row>
      </div>
    )
  }
}

export default StyledMachines
