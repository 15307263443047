import React from 'react'
import { Modal, Row, Col, Divider, Button, Collapse, Table } from 'antd'
import { messageTypes } from '../../../../actions/messages'
import { takeElementWithIdFromArr, getCookie, openNotificationPopup } from '../../../../actions/helpers'
import { quizzesForUser, quizForUser, adminFlagsForUser } from '../../../../actions/'
import dateformat from 'dateformat'
import FontAwesomeIcon from 'react-fontawesome'

const { Panel } = Collapse

class UsersModalSettings extends React.Component {
  state = {
    userData: {},
    quizzes: [],
    flags: [],
    showQuizData: null,
    showQuizDataFull: null
  }

  handleCancel = () => {
    const { setShowDataId } = this.props
    setShowDataId(null)
  }

  handleQuizCancel = () => {
    this.setQuizShowData(null)
  }

  setQuizShowData = (showQuizData) => { this.setState({ showQuizData }) }

  componentDidUpdate (prevProps, prevState) {
    const { dataId, data, language } = this.props
    const { showQuizData } = this.state

    // get user data and user quizzes
    if (prevProps.dataId !== dataId) {
      const userData = takeElementWithIdFromArr(dataId, data)
      this.setState({ userData })

      if (userData) {
        quizzesForUser(dataId, getCookie('_token')).then((json) => {
          if (json.status === 'ok') {
            this.setState({
              quizzes: json.response
            })
          } else if (json.status === 'err') {
            openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
          }
        })

        adminFlagsForUser(dataId, getCookie('_token')).then((json) => {
          if (json.status === 'ok') {
            this.setState({
              flags: json.response
            })
          } else if (json.status === 'err') {
            openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
          }
        })
      }
    }

    // get quiz details for user if requested
    if (showQuizData && prevState.showQuizData !== showQuizData) {
      quizForUser(showQuizData._id, dataId, getCookie('_token')).then((json) => {
        if (json.status === 'ok') {
          this.setState({
            showQuizDataFull: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      })
    }
  }

  render () {
    const { language, dataId, stacks } = this.props
    const { userData, quizzes, flags, showQuizData, showQuizDataFull } = this.state
    let quizzesInProgressFound = false
    let quizzesSolvedFound = false

    // define columns for failed answers tables
    const failedAnswersColumns = [
      {
        title: messageTypes[language].answer,
        dataIndex: 'answer',
        key: 'answer'
      },
      {
        title: messageTypes[language].date,
        dataIndex: 'created_at',
        key: 'created_at',
        defaultSortOrder: 'ascend',
        render: (text) => (
          <>
            {dateformat(text, 'yyyy-mm-dd HH:MM:ss')}
          </>
        )
      }
    ]
    let defaultStack

    return (
      userData
        ? (
          <>
            <Modal
              visible={!!dataId}
              title={
                messageTypes[language].user_details + ' ' + (userData && userData.name)
              }
              onCancel={this.handleCancel}
              footer={null}
            >
              <>
                <Row gutter={16}>
                  <Col className='gutter-row' span={8}>
                    <div>{messageTypes[language].login_email}</div>
                  </Col>
                  <Col className='gutter-row' span={16}>
                    <div>{userData.email}</div>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col className='gutter-row' span={8}>
                    <div>{messageTypes[language].account_type}</div>
                  </Col>
                  <Col className='gutter-row' span={16}>
                    <div>{messageTypes[language]['account_type_' + userData.origin]}</div>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col className='gutter-row' span={8}>
                    <div>{messageTypes[language].registration_date}</div>
                  </Col>
                  <Col className='gutter-row' span={16}>
                    <div>{userData.created_at !== undefined ? dateformat(userData.created_at, 'yyyy-mm-dd HH:MM:ss') : ''}</div>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col className='gutter-row' span={8}>
                    <div>{messageTypes[language].default_stack}</div>
                  </Col>
                  <Col className='gutter-row' span={16}>
                    <div>{userData.stack && stacks && (defaultStack = stacks.find(x => x._id === userData.stack)) && defaultStack.name + ' (' + defaultStack.type + ')'}</div>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col className='gutter-row' span={8}>
                    <div>{messageTypes[language].stacks}</div>
                  </Col>
                  <Col className='gutter-row' span={16}>
                    <div>{userData.stacks && stacks && stacks.filter(x => userData.stacks.includes(x._id)).map(x => <React.Fragment key={x._id}><span>{x.name} ({x.type})</span><br /></React.Fragment>)}</div>
                  </Col>
                </Row>

                <Divider>
                  {messageTypes[language].quizzes_in_progress}
                </Divider>
                <div>
                  {quizzes && quizzes.map((quiz, i) => {
                    if (quiz.started_at !== undefined && quiz.solved_at === undefined) {
                      quizzesInProgressFound = true
                      return (
                        <p key={i}>
                          {quiz.scenario.name[language]} <Button size='small' onClick={() => { this.setQuizShowData(quiz) }}>{messageTypes[language].show}</Button>
                        </p>
                      )
                    }
                    return ''
                  })}
                  {!quizzesInProgressFound ? messageTypes[language].quizzes_in_progress_empty : ''}
                </div>

                <Divider>
                  {messageTypes[language].quizzes_solved}
                </Divider>
                <div>
                  {quizzes && quizzes.map((quiz, i) => {
                    if (quiz.solved_at !== undefined) {
                      quizzesSolvedFound = true
                      return (
                        <p key={i}>
                          {quiz.scenario.name[language]} <Button size='small' onClick={() => { this.setQuizShowData(quiz) }}>{messageTypes[language].show}</Button>
                        </p>
                      )
                    }
                    return ''
                  })}
                  {!quizzesSolvedFound ? messageTypes[language].quizzes_solved_empty : ''}
                </div>

                <Divider>
                  {messageTypes[language].challenges_solved}
                </Divider>
                <div>
                  {
                    flags && flags.length > 0 && flags.map((stack, i) => (
                      stack.items.length > 0 && (
                        <div key={i} className='admin-challenge-solve'>
                          <p>{stack.name}</p>
                          {stack.items.map((val, i) => (
                            <div key={i}>{val.name[language]} ({dateformat(val.capture_date, 'yyyy-mm-dd HH:MM:ss')})<br /></div>
                          ))}
                        </div>
                      )
                    ))
                  }
                  {!flags || flags.length === 0 ? messageTypes[language].no_solves : ''}
                </div>
              </>
            </Modal>
            {showQuizData !== null
              ? (
                <Modal
                  visible={!!showQuizData}
                  title={
                    messageTypes[language].user_quiz_details + ' ' +
                  (showQuizData && showQuizData.scenario && showQuizData.scenario.name && showQuizData.scenario.name[language])
                  }
                  onCancel={this.handleQuizCancel}
                  footer={null}
                >
                  <Row gutter={16}>
                    <Col className='gutter-row' span={8}>
                      <div>{messageTypes[language].description}</div>
                    </Col>
                    <Col className='gutter-row' span={16}>
                      <div>{showQuizData && showQuizData.desc && showQuizData.desc[language]}</div>
                    </Col>
                  </Row>
                  {showQuizData.started_at !== undefined
                    ? (
                      <Row gutter={16}>
                        <Col className='gutter-row' span={8}>
                          <div>{messageTypes[language].quiz_started_at}</div>
                        </Col>
                        <Col className='gutter-row' span={16}>
                          <div>{dateformat(showQuizData.started_at, 'yyyy-mm-dd HH:MM:ss')}</div>
                        </Col>
                      </Row>
                      )
                    : ''}
                  {showQuizData.solved_at !== undefined
                    ? (
                      <Row gutter={16}>
                        <Col className='gutter-row' span={8}>
                          <div>{messageTypes[language].quiz_solved_at}</div>
                        </Col>
                        <Col className='gutter-row' span={16}>
                          <div>{dateformat(showQuizData.solved_at, 'yyyy-mm-dd HH:MM:ss')}</div>
                        </Col>
                      </Row>
                      )
                    : ''}

                  <Divider>
                    {messageTypes[language].questions}
                  </Divider>
                  <div>
                    <Collapse accordion className='user-quiz-details-collapse'>
                      {showQuizDataFull && showQuizDataFull.data.map((question, i) => {
                        return (
                          <Panel
                            header={
                              <>
                                <span>{question.question.name[language]} {question.solved ? <FontAwesomeIcon className='success' name='check' /> : <FontAwesomeIcon className='danger' name='close' />} </span>
                                <span>{
                                  question.incorrect_attempts > 0 ? messageTypes[language].incorrect_attempts + ': ' + question.incorrect_attempts : ''
                                }
                                </span>
                              </>
                            } disabled={question.incorrect_attempts === 0 && !question.solved} key={i}
                          >

                            {question.solved && question.incorrect_attempts === 0
                              ? <span>{messageTypes[language].answered_first_time}</span>
                              : ''}

                            {question.incorrect_attempts > 0
                              ? (
                                <Table
                                  className='user-quiz-details-table'
                                  dataSource={question.incorrect_attempts_list.map((x, y) => { x.key = y; return x })}
                                  columns={failedAnswersColumns} size='small'
                                />
                                )
                              : ''}
                          </Panel>
                        )
                      })}
                    </Collapse>
                  </div>
                </Modal>
                )
              : ''}
          </>
          )
        : ''
    )
  }
}

export default UsersModalSettings
