import React, { Fragment } from 'react'
import { getCookie, confirm } from '../../actions/helpers'
import { messageTypes } from '../../actions/messages'
import config from '../../config'
import {
  Form, Select, Input, Button, Upload, Divider, Checkbox, Progress
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'

const { Option } = Select

class ResourceFilesFormComponent extends React.Component {
  fileTypes = ['pdf', 'txt', 'doc', 'docx', 'zip', 'jpg', 'png', 'xls', 'xlsx']
  types = ['file', 'url', 'youtube']

  formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  }

  state = {
    filesArr: [],
    files: {},
    filesIds: {},
    filesTypes: {},
    languages: config.languages,
    progress: {},
    types: {}
  }

  // on load set form default values
  componentDidMount () {
    this.setDefaultValues()
  }

  // update form data when record changed or new record will be added
  componentDidUpdate (prevProps, prevState) {
    const { data, isNewRecord } = this.props

    if (data._id !== prevProps.data._id || isNewRecord !== prevProps.isNewRecord) {
      this.setDefaultValues()
    }
  }

  // prepare form data for existing record
  prepareFormExistingValues (data) {
    return {}
  }

  // prepare values when on form submit
  prepareFormValues () {
    return {}
  }

  handleSubmit = (values) => {
    const { data, isNewRecord, addRecord, updateRecord } = this.props
    const obj = this.prepareFormValues(values)

    obj.files = []
    for (const i of this.state.filesArr) {
      const filesObj = {
        _id: i._id
      }
      filesObj.label = {}
      filesObj.url = {}

      // prepare label fields for any language
      // eslint-disable-next-line
      this.state.languages.forEach((lang, x) => {
        filesObj.label[lang] = values['file_label_' + lang + '_' + i._id]
        filesObj.url[lang] = values['url_' + lang + '_' + i._id]
      })

      filesObj.file_type = values['file_type_' + i._id]
      filesObj.type = values['type_' + i._id]
      filesObj.active = i.active

      obj.files.push(filesObj)
    }
    if (isNewRecord) { addRecord(obj) } else { updateRecord(data._id, obj) }
  }

  handleFileTypeChange = (fileType, i) => {
    const filesTypes = this.state.filesTypes

    filesTypes[i] = fileType

    this.setState({ filesTypes })
  }

  handleTypeChange = (type, i) => {
    const types = this.state.types

    types[i] = type

    this.setState({ types })
  }

  setDefaultValues = () => {
    const { data, resources } = this.props

    if (data && Object.keys(data).length > 0) {
      const fieldsVal = this.prepareFormExistingValues(data)

      const filesIds = {}
      const filesTypes = {}
      const types = {}
      // const filesActive = {}
      const filesArr = []

      if (resources) {
        for (let i = 0; i < resources.length; i++) {
          const fileId = resources[i]._id
          const filex = {
            _id: fileId
          }

          // prepare form values for field label and any language
          this.state.languages.forEach((lang, x) => {
            fieldsVal['file_label_' + lang + '_' + fileId] = resources[i].label && resources[i].label[lang]
            fieldsVal['url_' + lang + '_' + fileId] = resources[i].url && resources[i].url[lang]

            if (filex.label === undefined) {
              filex.label = {}
            }

            filex.label[lang] = resources[i].label && resources[i].label[lang]

            if (filex.path === undefined) {
              filex.path = {}
            }
            filex.path[lang] = resources[i].path && resources[i].path[lang]

            if (filex.url === undefined) {
              filex.url = {}
            }
            filex.url[lang] = resources[i].url && resources[i].url[lang]
          })

          fieldsVal['file_type_' + fileId] = resources[i].file_type
          fieldsVal['type_' + fileId] = resources[i].type
          fieldsVal['file_active_' + fileId] = resources[i].active
          filex.active = resources[i].active

          filesTypes[fileId] = resources[i].file_type
          types[fileId] = resources[i].type

          // prepare form values for field path and any language
          this.state.languages.forEach((lang, x) => {
            fieldsVal['file_path_' + lang + '_' + fileId] = resources[i].path && resources[i].path[lang]

            if (filesIds[fileId] === undefined) {
              filesIds[fileId] = {}
            }
            filesIds[fileId][lang] = resources[i].path && resources[i].path[lang]
          })

          filesArr.push(filex)
        }

        this.setState({ filesIds, filesTypes, types, filesArr, files: {} })
      }
      this.formRef.current.setFieldsValue(fieldsVal)
    } else if (this.prepareFormDefaultValues) {
      this.formRef.current.setFieldsValue(this.prepareFormDefaultValues())
    }
  }

  _removeResource = (id) => {
    confirm('remove_real_file_question', this.props.language, () => {
      this.removeResource(id, () => {
        const filesArr = this.state.filesArr.filter(x => x._id !== id)
        this.setState({ filesArr })
      })
    })
  }

  addFile = (id, callAfterCreate) => {
  }

  adminCopyResourceFileToLang = (id, lang, dstLang) => {
  }

  removeSingleFile = (id, lang, callAfterCreate) => {
  }

  handleAddSingleFile = (id) => {
    this.addFile && this.addFile(id, (file) => {
      const filesArr = this.state.filesArr
      filesArr.push(file)

      this.setState({ filesArr })
    })
  }

  setProgress = (resourceId, lang, prgs) => {
    const progress = this.state.progress

    progress[resourceId + '_' + lang] = prgs

    this.setState({ progress })

    this.props.setUploading && this.props.setUploading(prgs !== undefined)
  }

  setStatus = (status) => {
    this.setState({ status })
  }

  setSelectedFile = (selectedFile) => {
    this.setState({ selectedFile })
  }

  handleFileChange = (event) => {
    const file = event.target.files[0]
    this.setSelectedFile(file)
  }

  onHandleFileUpload = (options) => {
    const { data: uploadData, action, onError, file: selectedFile } = options
    try {
      const chunkSize = 5 * 1024 * 1024 // 5MB
      const totalChunks = Math.ceil(selectedFile.size / chunkSize)
      const chunkProgress = 100 / totalChunks
      let chunkNumber = 0
      let start = 0
      let end = 0

      if (totalChunks === 1) {
        end = selectedFile.size
      }

      const uploadNextChunk = async () => {
        if (end <= selectedFile.size) {
          const chunk = selectedFile.slice(start, end)
          const formData = new FormData()
          formData.append('file', chunk)
          formData.append('chunkNumber', chunkNumber)
          formData.append('totalChunks', totalChunks)
          formData.append('originalname', selectedFile.name)

          fetch(action, {
            method: 'POST',
            body: formData,
            headers: {
              Authorization: 'Bearer ' + getCookie('_token')
            }
          })
            .then((response) => response.json())
            .then((data) => {
              this.setProgress(uploadData.resourceId, uploadData.lang, Math.round(Number((chunkNumber + 1) * chunkProgress)))

              chunkNumber++
              start = end

              if (chunkNumber + 1 === totalChunks) {
                end = selectedFile.size
              } else {
                end = start + chunkSize
              }

              uploadNextChunk()
            })
            .catch((error) => {
              console.error('Error uploading chunk:', error)
            })
        } else {
          this.setProgress(uploadData.resourceId, uploadData.lang, undefined)
          this.setSelectedFile(null)
          const filesArr = this.state.filesArr
          const file = filesArr.find(x => x._id === uploadData.resourceId)

          file.path[uploadData.lang] = 'true'

          this.setState({ filesArr })
        }
      }

      uploadNextChunk()
    } catch (err) {
      console.log('Error: ', err)
      onError({ err })
    }
  }

  copyFile = (fileId, srcLang, dstLang) => {
    this.adminCopyResourceFileToLang(fileId, srcLang, dstLang)

    const filesArr = this.state.filesArr
    const file = filesArr.find(x => x._id === fileId)

    file.path[dstLang] = file.path[srcLang]

    this.setState({ filesArr })
  }

  // render files form fields
  fileFieldsRender (resourcesDir, id, apiModuleName, disabled) {
    const { language } = this.props
    const { progress } = this.state

    const uploading = Object.keys(progress).some(x => progress[x] !== undefined)

    return (
      <>
        <Form.Item
          label={messageTypes[language].files}
          {...this.formItemLayout}
        >
          <Button disabled={disabled} onClick={() => this.handleAddSingleFile(id)}>{messageTypes[language].add_file}</Button>
        </Form.Item>
        {
          this.state.filesArr.map((val, i) =>
            <Fragment key={i}>
              {i !== this.state.filesArr.length && <Divider />}
              <div className='file-remove-container'><Button onClick={() => { this._removeResource(val._id) }} disabled={disabled || uploading}>Usuń</Button></div>
              {!this.disableFileLabel &&
                this.state.languages.map((lang, ix) => {
                  return (
                    <Form.Item
                      name={'file_label_' + lang + '_' + val._id}
                      title={val._id}
                      label={messageTypes[language]['file_label_' + lang] + ' ' + (i + 1)}
                      {...this.formItemLayout}
                      rules={config.default_lang === lang ? [{ required: true, message: messageTypes[language].field_required }] : []}
                      key={ix}
                    >
                      <Input
                        disabled={disabled}
                        onChange={(x) => {
                          const filesArr = this.state.filesArr
                          const field = filesArr.find(x => x._id === val._id)

                          field.label[lang] = x.target.value

                          this.setState({ filesArr })
                        }}
                      />
                    </Form.Item>
                  )
                })}

              <Form.Item
                name={'type_' + val._id}
                label={messageTypes[language].resource_type + ' ' + (i + 1)}
                {...this.formItemLayout}
                rules={[{ required: true, message: messageTypes[language].field_required }]}
              >
                <Select disabled={disabled} onChange={x => this.handleTypeChange(x, val._id)}>
                  {this.types && this.types.map((ftype, i) =>
                    <Option key={i} value={ftype}>{ftype}</Option>)}
                </Select>
              </Form.Item>

              {this.state.types[val._id] === 'file' &&
                <Form.Item
                  name={'file_type_' + val._id}
                  label={messageTypes[language].file_type + ' ' + (i + 1)}
                  {...this.formItemLayout}
                  rules={[{ required: true, message: messageTypes[language].field_required }]}
                >
                  <Select disabled={disabled} onChange={x => this.handleFileTypeChange(x, val._id)}>
                    {this.fileTypes && this.fileTypes.map((ftype, i) =>
                      <Option key={i} value={ftype}>{ftype}</Option>)}
                  </Select>
                </Form.Item>}

              {(this.state.types[val._id] === 'url' || this.state.types[val._id] === 'youtube') && this.state.languages.map((lang, ix) => {
                return (
                  <Form.Item
                    name={'url_' + lang + '_' + val._id}
                    title={val._id}
                    label={messageTypes[language]['file_url_' + lang] + ' ' + (i + 1)}
                    {...this.formItemLayout}
                    rules={config.default_lang === lang ? [{ required: true, message: messageTypes[language].field_required }] : []}
                    key={ix}
                  >
                    <Input
                      disabled={disabled}
                      onChange={(x) => {
                        const filesArr = this.state.filesArr
                        const field = filesArr.find(x => x._id === val._id)

                        field.url[lang] = x.target.value

                        this.setState({ filesArr })
                      }}
                    />
                  </Form.Item>
                )
              })}

              <Form.Item
                name={'file_active_' + val._id}
                label={messageTypes[language].file_active + ' ' + (i + 1)}
                {...this.formItemLayout}
              >
                <Checkbox
                  defaultChecked={this.state.filesArr.find(x => x._id === val._id)?.active || ''}
                  onChange={(x) => {
                    const filesArr = this.state.filesArr
                    const field = filesArr.find(x => x._id === val._id)

                    field.active = x.target.checked

                    this.setState({ filesArr })
                  }}
                />
              </Form.Item>

              {this.state.types[val._id] === 'file' && this.state.languages.map((langx, ix) => {
                const _this = this

                return (
                  <Form.Item
                    name={'file_path_' + langx + '_' + val._id}
                    label={messageTypes[language]['file_' + langx] + ' ' + (i + 1)}
                    {...this.formItemLayout}
                    key={ix}
                    className='file-upload-input-container'
                    extra={
                      <>
                        {this.state.progress[val._id + '_' + langx] > 0 && <Progress percent={this.state.progress[val._id + '_' + langx]} />}
                        {!this.disableFileLabel && val.path[langx] !== 'false' && val.path[(langx === 'pl' && 'en') || 'pl'] === 'false' &&
                          <Button onClick={() => this.copyFile(val._id, langx, (langx === 'pl' && 'en') || 'pl')} disabled={disabled || uploading}>
                            {messageTypes[language]['clone_from_lang_' + langx + '_to_' + ((langx === 'pl' && 'en') || 'pl')]}
                          </Button>}
                        <Upload
                          name='file'
                          disabled={disabled}
                          data={{ resourceId: val._id, lang: langx }}
                          action={`${config.api_url}/admin/${apiModuleName}/resource/upload/${id}/${val._id}/${langx}`}
                          customRequest={this.onHandleFileUpload}
                          accept={
                            (this.forceExtension && '.' + this.forceExtension) ||
                            (this.state.filesTypes[val._id] ? '.' + this.state.filesTypes[val._id] : '')
                          }
                          fileList={((val.path[langx] === 'true' || (val.path[langx] !== 'false' && val.path[langx] !== 'true')) && [
                            {
                              uid: 1,
                              name: messageTypes[language].file
                            }
                          ]) || []}
                          headers={{
                            Authorization: 'Bearer ' + getCookie('_token')
                          }}
                          onRemove={() => {
                            return new Promise(function (resolve, reject) {
                              return confirm('remove_real_file_question', language, () => {
                                try {
                                  _this.removeSingleFile(val._id, langx, () => resolve(true))
                                } catch (err) {
                                  console.log('Cannot remove file')
                                }
                              }, () => resolve(false))
                            })
                          }}
                          onChange={(info) => {
                            if (info.file.status === 'done') {
                              const filesArr = this.state.filesArr
                              const file = filesArr.find(x => x._id === val._id)

                              file.path[langx] = 'true'

                              this.setState({ filesArr })
                            } else if (info.file.status === 'error') {
                              console.log('error when uploading file')
                            } else if (info.file.status === 'removed') {
                              const filesArr = this.state.filesArr
                              filesArr[i].path[langx] = 'false'

                              this.setState({ filesArr })
                            }
                          }}
                          maxCount={1}
                        >
                          {val.path[langx] === 'false' && this.state.progress[val._id + '_' + langx] === undefined &&
                            <Button
                              disabled={disabled || uploading}
                              icon={<UploadOutlined />}
                            >
                              {messageTypes[language].send_file}
                            </Button>}
                        </Upload>
                      </>
                   }
                  />
                )
              })}
            </Fragment>
          )
}
      </>
    )
  }
}

export default ResourceFilesFormComponent
