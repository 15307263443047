import React, { Component } from 'react'
import ChallengeCard from './challengeCard'
import { Row, Col, Divider } from 'antd'
import { options } from '../settings/maps/mapDraw'

class StyledChallenges extends Component {
  render () {
    const { challenges, setModalVisible, setChallenge, language } = this.props
    const icons = { ...options.groups, ...this.props.icons }

    return (
      <div className={`challenges ${challenges.length ? 'fade-in' : 'fade-out'}`}>
        {
          challenges.length
            ? challenges.map((element, n) =>
              (
                <React.Fragment key={n}>
                  <div className='challenge-category-head'>
                    {element.icon && icons[element.icon]?.image &&
                      <div className='challenge-category-icon'>
                        <img style={{ height: '52px' }} src={icons[element.icon].image} alt={element.icon} />
                      </div>}

                    <h1 className='challenge-category-name'>{element.name[language]}</h1>
                  </div>

                  <Row gutter={[30, 30]}>
                    {element.items
                      ? element.items.map((item, x) =>
                        <Col
                          style={{ marginBottom: 8 }}
                          lg={4} md={4} sm={24} xs={24}
                          key={x}
                        >
                          <ChallengeCard
                            element={item}
                            language={language}
                            setModalVisible={setModalVisible}
                            setChallenge={setChallenge}
                          />
                        </Col>
                      )
                      : ''}
                  </Row>
                  {/*n !== challenges.length - 1 && <Divider />*/}
                </React.Fragment>)
            )
            : ''
        }
      </div>
    )
  }
}

export default StyledChallenges
