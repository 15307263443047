import React, { Component } from 'react'
import { Table, Input, Button, Tooltip, Progress, Space } from 'antd'
import { SearchOutlined, FlagOutlined, CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'
import { messageTypes } from '../../../actions/messages'
import Highlighter from 'react-highlight-words'
import config from '../../../config/'
import moment from 'moment'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as ChartTooltip, Legend, ResponsiveContainer } from 'recharts'

class StyledRanking extends Component {
  state = {
    view: 'detailed_table',
    hasHorizontalScrollbar: undefined,
    maxLeft: true,
    maxRight: false,
    flagCellHeight: 0,
    activeLine: undefined
  }

  // ###########################################
  getColumnSearchProps = (dataIndex, language) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => { this.searchInput = node }}
          placeholder={messageTypes[language]['table_search_' + dataIndex]}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          {messageTypes[language].search}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size='small'
          style={{ width: 90 }}
        >
          {messageTypes[language].clear}
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined className={filtered ? 'active' : ''} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    )
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '' })
  }
  // ###########################################

  componentDidMount () {
    window.addEventListener('resize', () => {
      this.checkScroll()
    })

    this.prepareScrollEvent()
  }

  checkScroll = () => {
    if (this.state.view === 'detailed_table') {
      const rawElem = document.getElementsByClassName('ant-table-content')
      const element = rawElem && rawElem[0]

      if (element) {
        const hasHorizontalScrollbar = element.scrollWidth - element.clientWidth
        const rawFlagCell = document.getElementsByClassName('scoreboard-flag-cell-first')
        const flagCell = rawFlagCell && rawFlagCell[0]

        if (this.state.hasHorizontalScrollbar !== hasHorizontalScrollbar || this.state.hasHorizontalScrollbar === undefined) {
          this.setState({ hasHorizontalScrollbar, flagCellHeight: flagCell?.clientHeight })
        }
      }
    }
  }

  prepareScrollEvent = () => {
    const rawElem = document.getElementsByClassName('ant-table-content')
    const element = rawElem && rawElem[0]

    element?.addEventListener('scroll', (e) => {
      const rawElem = document.getElementsByClassName('ant-table-content')
      const element = rawElem && rawElem[0]
      const currentScroll = element.scrollLeft
      const maxScroll = element.scrollWidth - element.clientWidth

      if (currentScroll === 0) {
        this.setState({ maxLeft: true, maxRight: false })
      } else if (currentScroll === maxScroll) {
        this.setState({ maxLeft: false, maxRight: true })
      } else if (currentScroll > 0) {
        this.setState({ maxLeft: false, maxRight: false })
      }
    })
  }

  componentDidUpdate (prevProps, prevState) {
    this.checkScroll()

    if (prevState.view !== 'detailed_table' && this.state.view === 'detailed_table') {
      this.prepareScrollEvent()
    }
  }

  setDetailedTableColumns () {
    const { language, extendedView, stack, options } = this.props

    const columns = [{
      title: messageTypes[language].place,
      dataIndex: 'key',
      key: 'place',
      className: 'scoreboard-static-cell',
      // render: (number) => (
      //   number >= 1 && number <= 3 ? <><img src={'/images/' + number + '.png'} alt={number} /></> : number
      // ),
      fixed: extendedView && 'left',
      width: 100
    }, {
      title: messageTypes[language].user,
      dataIndex: 'name',
      key: 'name',
      className: 'scoreboard-static-cell',
      fixed: extendedView && 'left',
      width: (extendedView && 150) || 300
    }, {
      title: messageTypes[language].score,
      dataIndex: 'score',
      key: 'score',
      className: 'scoreboard-static-cell',
      fixed: extendedView && 'left',
      width: (extendedView && 90) || undefined
    }]

    let x = 0

    // prepare dynamic columns with flags
    this.props.columns && this.props.columns.forEach((column, i) => {
      const childs = []

      if (column.flags && column.flags.length > 0) {
        column.flags.forEach((flag, i) => {
          childs.push(
            {
              title: (
                <Tooltip placement='bottom' title={messageTypes[language].solves2 + ': ' + flag.solved_count}>
                  <p>
                    {(typeof flag.name === 'object' && flag.name[language]) ?? flag.name} ({flag.current_value})
                  </p>
                </Tooltip>),
              dataIndex: 's' + x,
              key: 's' + x,
              className: 'scoreboard-flag-cell' + (i === column.flags.length - 1 ? ' scoreboard-flag-cell-last' : '') + (x === 0 ? ' scoreboard-flag-cell-first' : ''),
              render: (done) => (
                done ? <FlagOutlined /> : ''
              )
            })
          x += 1
        })

        const columnName = ((typeof column.name === 'object' && column.name[language]) ?? column.name)

        columns.push({
          title:
            (
              <p className='scoreboard-group-parent'>
                {column.icon && options.groups[column.icon]?.image &&
                  <Tooltip placement='left' title={columnName}>
                    <span className={(stack?.ctf_type === 'jeopardy' && 'scoreboard-group-child') || ''}>
                      <img src={options.groups[column.icon].image} alt={column.icon} />
                    </span>
                  </Tooltip>}
                <span className={(stack?.ctf_type === 'jeopardy' && 'scoreboard-group-child') || ''}>{columnName}</span>
              </p>
            ),
          children: childs,
          className: 'scoreboard-flag-cell' + (column.flags.length === 1 ? ' fixed-scoreboard-width' : '')
        })
      }
    })

    return columns
  }

  setProgressTableColumns () {
    const { language } = this.props

    return [{
      title: messageTypes[language].place,
      dataIndex: 'key',
      key: 'place',
      className: 'scoreboard-static-cell',
      // render: (number) => (
      //   number >= 1 && number <= 3 ? <><img src={'/images/' + number + '.png'} alt={number} /></> : number
      // ),
      width: 100
    }, {
      title: messageTypes[language].user,
      dataIndex: 'name',
      key: 'name',
      className: 'scoreboard-static-cell',
      width: 250
    }, {
      title: messageTypes[language].score,
      dataIndex: 'score',
      key: 'score',
      className: 'scoreboard-static-cell',
      render: (points, record) => {
        return <Progress percent={(parseInt(points) / this.props.maxPoints) * 100} format={(percent) => points} size={20} />
      }
    }]
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { ranking, scoreboardFrozen, language, extendedView, stack, chartData, adminAuth } = this.props
    const { view, activeLine, flagCellHeight } = this.state
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    const ticks = []

    if (stack && stack !== 'switching') {
      const start = moment(stack.ctf_start_date).unix()
      const end = moment(stack.ctf_end_date).unix()
      const step = 14400
      let tick = start + step

      ticks.push(start)

      while (tick < end) {
        ticks.push(tick)
        tick += step
      }

      ticks.push(end)
    }

    const detailedTableColumns = this.setDetailedTableColumns()
    const progressTableColumns = extendedView && this.setProgressTableColumns()

    // const flagCapturesColumns = [
    //   {
    //     title: messageTypes[language].team,
    //     dataIndex: 'name',
    //     key: 'name',
    //     width: 250
    //   },
    //   {
    //     title: 'Nazwa zadania',//messageTypes[language].team,
    //     dataIndex: 'challenge_name',
    //     key: 'challenge_name',
    //     render: (name, record) => {
    //       return name[language]
    //     }
    //   },
    //   {
    //     title: 'Czas zdobycia',//messageTypes[language].score,
    //     dataIndex: 'created_at',
    //     key: 'created_at',
    //     render: (points, record) => {
    //       return moment(points).fromNow()
    //     }
    //   }]

    const maxCount = 10
    const scrollStep = 150

    const buttonLeft = (
      <Button
        className='table-button-left'
        style={{ top: (((extendedView && 98) || 47) + (scoreboardFrozen ? 99 : 0) + flagCellHeight * 0.5) + 'px' }}
        onClick={() => {
          const rawElem = document.getElementsByClassName('ant-table-content')
          const element = rawElem && rawElem[0]
          const currentScroll = element.scrollLeft

          if (currentScroll - scrollStep > 0) {
            element.scrollLeft = currentScroll - scrollStep
            this.setState({ maxLeft: false, maxRight: false })
          } else {
            element.scrollLeft = 0
            this.setState({ maxLeft: true, maxRight: false })
          }
        }}
        icon={<CaretLeftOutlined />}
      />
    )

    const buttonRight = (
      <Button
        className='table-button-right'
        style={{ top: (((extendedView && 98) || 47) + (scoreboardFrozen ? 99 : 0) + flagCellHeight * 0.5) + 'px' }}
        onClick={() => {
          const rawElem = document.getElementsByClassName('ant-table-content')
          const element = rawElem && rawElem[0]
          const currentScroll = element.scrollLeft
          const maxScroll = element.scrollWidth - element.clientWidth

          if (currentScroll + scrollStep <= maxScroll) {
            element.scrollLeft = element.scrollLeft + scrollStep
            this.setState({ maxRight: false, maxLeft: false })
          } else {
            element.scrollLeft = maxScroll
            this.setState({ maxRight: true, maxLeft: false })
          }
        }}
        icon={<CaretRightOutlined />}
      />
    )

    const rankingTop = ranking.filter((item, i) => i < maxCount)
    const logoMaxHeight = flagCellHeight < 200 ? flagCellHeight : 200

    return (
      <>
        {extendedView && ranking.length > 0 &&
          (
            <div style={{ textAlign: 'left', marginBottom: '20px' }} className={'ranking-actions' + (!adminAuth ? ' not-admin' : '')}>
              <Space>
                <Button
                  onClick={() => this.setState({ view: 'detailed_table' })}
                  className={(view === 'detailed_table' && 'view-selected') || ''}
                >
                  {messageTypes[language].detailed_table}
                </Button>
                <Button
                  onClick={() => this.setState({ view: 'progress' })}
                  className={(view === 'progress' && 'view-selected') || ''}
                >
                  {messageTypes[language].ranking_progress}
                </Button>
                <Button
                  onClick={() => this.props.exportRankingCsv()}
                >
                  {messageTypes[language].export_to_csv}
                </Button>
              </Space>
            </div>)}

        {scoreboardFrozen && <div className='scoreboard-frozen'>{messageTypes[language].scoreboard_is_frozen}</div>}

        {extendedView && view === 'progress' && ranking.length > maxCount &&
          <h3>{messageTypes[language].stats_top_x_teams.replace('_COUNT_', maxCount)}</h3>}

        {extendedView && view === 'progress' && chartData && this.props.stack && chartData.length > 0 &&
          <ResponsiveContainer width='100%' height={500}>
            <LineChart
              data={chartData}
              margin={{
                top: 50,
                right: 30
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis
                dataKey='timestamp'
                type='number'
                domain={[moment(this.props.stack.ctf_start_date).unix(), moment(this.props.stack.ctf_end_date).unix()]}
                tickFormatter={timeStr => moment.unix(timeStr).format('HH:mm')}
                ticks={ticks}
              />
              <YAxis domain={[0, 600]} />
              <ChartTooltip
                labelFormatter={timeStr => moment.unix(timeStr).format('HH:mm')}
                contentStyle={{ backgroundColor: '#40434e' }}
                itemSorter={(item) => {
                  return item.value * -1
                }}
              />
              <Legend
                onMouseOver={(x) => this.setState({ activeLine: x.value })}
                onMouseLeave={(x) => this.setState({ activeLine: undefined })}
                formatter={(value, entry, index) => (
                  <span style={activeLine === value ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}>{value}</span>
                )}
              />
              {rankingTop.map((x, i) => {
                return (
                  <Line
                    key={i}
                    type='monotone'
                    dataKey={x.name}
                    stroke={x.color}
                    dot={false}
                    onMouseOver={() => this.setState({ activeLine: x.name })}
                    onMouseLeave={() => this.setState({ activeLine: undefined })}
                    strokeWidth={activeLine === x.name ? 4 : 1}
                  />
                )
              })}
            </LineChart>
          </ResponsiveContainer>}

        {extendedView && view === 'progress' &&
          <Table
            className={`${isSafari ? 'safari ' : ''}ranking-table ${ranking.length ? 'fade-in' : ''} ${!adminAuth ? ' not-admin' : ''}`}
            dataSource={rankingTop}
            columns={progressTableColumns}
            pagination={false}
          />}

        {view === 'detailed_table' &&
          <>
            {extendedView && config.app_ranking_logo &&
              <img
                src={'/images/customization/' + config.app_ranking_logo}
                alt='Logo of app'
                style={{
                  top: (flagCellHeight ? (((extendedView && 95) || 63) + (scoreboardFrozen ? 99 : 0) - 100 + flagCellHeight * 0.5) : 70) + 'px',
                  left: 170 - (logoMaxHeight / 2),
                  maxWidth: logoMaxHeight,
                  maxHeight: logoMaxHeight
                }}
                className={`ranking-image ${ranking.length ? 'fade-in' : ''}`}
              />}

            {(adminAuth && extendedView && (ranking.length && this.state.hasHorizontalScrollbar && !this.state.maxLeft && buttonLeft)) || undefined}
            {(adminAuth && extendedView && (ranking.length && this.state.hasHorizontalScrollbar && !this.state.maxRight && buttonRight)) || undefined}

            <Table
              className={`${isSafari ? 'safari ' : ''}ranking-table ${ranking.length && stack !== 'switching' ? 'fade-in' : ''} ${!adminAuth ? ' not-admin' : ''}`}
              dataSource={ranking}
              columns={detailedTableColumns}
              pagination={false}
              // scroll={false}
              scroll={adminAuth ? {
                x: (detailedTableColumns.length - 3) * ((isSafari && 500) || 250)
              } : undefined}
            />
          </>}
      </>
    )
  }
}

export default StyledRanking
