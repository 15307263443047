import React from 'react'
import { Modal } from 'antd'
import { messageTypes } from '../../../../actions/messages'
import StackTypeConfForm from '../../../../styledComponents/sections/settings/stackTypeConf/form'

class StackTypeConfModalSettings extends React.Component {
  state = {
    singleData: {}
  }

  handleCancel = () => {
    const { showConfModal } = this.props
    showConfModal(false)
  }

  componentDidUpdate (prevProps, prevState) {
    const { dataId, data, stackType } = this.props
    const contentPack = data?.find(x => x.type === stackType)

    if (prevProps.dataId !== dataId) {
      const singleData = contentPack
      this.setState({ singleData })
    }
  }

  render () {
    const {
      language, loading, dataId, updateRecord
    } = this.props
    const { singleData } = this.state

    return (
      <div>
        <Modal
          maskClosable={false}
          width={1000}
          visible={!!dataId}
          title={this.props.stackType}
          onCancel={this.handleCancel}
          footer={null}
        >
          <StackTypeConfForm
            data={singleData || {}}
            language={language}
            onCancel={this.handleCancel}
            updateRecord={updateRecord}
            loading={loading}
            stackType={this.props.stackType}
          />
        </Modal>
      </div>
    )
  }
}

export default StackTypeConfModalSettings
