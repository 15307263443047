import React, { Component } from 'react'
import { Layout, Menu, Drawer, Button, Tag } from 'antd'
import { SettingOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { pathNames, settingPathNames, messageTypes } from '../../actions/messages'
import { renderIcon } from '../../actions/helpers'
import config, { views } from '../../config/'
import CtfNavbarClock from '../../components/common/ctfNavbarClock'

const { Sider } = Layout
const SubMenu = Menu.SubMenu
const MenuItemGroup = Menu.ItemGroup

// class AddRecordButton extends Component {
//   state = {
//     visible: false
//   }
//
//   componentDidMount () {
//     this.setState({ visible: true })
//   }
//
//   render () {
//     const { showAddRecordModal, title, disabled } = this.props
//     return (
//       <div className={`add-record-button ${this.state.visible && 'fade-in'}`}>
//         <Button onClick={() => { showAddRecordModal(true) }} disabled={disabled}>
//           {title}
//         </Button>
//       </div>
//     )
//   }
// }
//
// export default AddRecordButton



class Navbar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      collapsed: false,
      screenWidth: null,
      settingsOpened: false
    }
  }

  componentDidMount () {
    this.updateWindowDimensions()
    window.addEventListener('resize', () => { this.updateWindowDimensions() })
  }

  updateWindowDimensions () {
    if (window.innerWidth < 768) {
      this.handleCollapse(true)
    } else {
      this.handleCollapse(false)
    }
    // this.setState({ screenWidth: window.innerWidth });
  }

  handleCollapse = (collapsed) => {
    this.setState({ collapsed })

    if (this.state.settingsOpened) {
      this.setState({ openedBeforeCollapse: true })
    } else if (this.state.openedBeforeCollapse) {
      this.setState({ settingsOpened: true, openedBeforeCollapse: false })
    }
  }

  setSelected () {
    const path = window.location.pathname
    let returnPath = '0'
    pathNames.forEach((val, i) => {
      if (path === val.path) {
        returnPath = i.toString()
      }
    })

    if (path === '/settings') {
      returnPath = '6'
    } else if (path === '/settings/users') {
      returnPath = '7'
    } else if (path === '/settings/scenarios') {
      returnPath = '8'
    } else if (path === '/settings/machines') {
      returnPath = '9'
    } else if (path === '/settings/quiz') {
      returnPath = '10'
    }
    return returnPath
  }

  showDrawer = (open) => {
    this.setState({ open })
  }
  render () {
    const { showAddRecordModal, title, disabled } = this.props
    return (
      <div className={`add-record-button ${this.state.visible && 'fade-in'}`}>
        <Button onClick={() => { showAddRecordModal(true) }} disabled={disabled}>
          {title}
        </Button>
      </div>
    )
  }
  render () {
    const { language, auth, stack, stacks } = this.props
    // if (!language) {
    //   return (<div />)
    // }
    const roleSuperAdmin = (auth === views.sa)
    const roleAdmin = (auth === views.ad)
    const roleUser = (auth === views.us || auth === views.ucr)
    const roleUserRankingOnly = (auth === views.ur)

    const currentStackId = stack?._id
    const switchStack = undefined

// console.log('menu render', auth, roleSuperAdmin, roleAdmin, roleUser, roleUserRankingOnly)

    // const aff = () => { this.props.switchStack(stack); this.showDrawer(false) }
// return 'eeeeeeeeeeeeeee'
{/*<Menu
  defaultSelectedKeys={[this.setSelected()]}
  defaultOpenKeys={[parseInt(this.setSelected()) > 6 ? 'sub-settings' : '']}
  mode='inline'
  // theme='dark'
  mode="horizontal"
  onOpenChange={(x) => this.setState({ settingsOpened: x?.includes('sub-settings') })}
  >*/}
    return (


<>
<Menu className='menu-top' mode={this.props.mode || 'horizontal'}>

          {pathNames.map((val, i) =>
            (((val.authOnly && auth) ||
               (val.authOnly === false) ||
               (val.authUserRankingOnly && roleUserRankingOnly) ||
               (val.authUser && (roleSuperAdmin || roleAdmin || roleUser))) &&
                 (
                   (!val.hideOnCtf && val.ctfType === undefined) || // when hideOnCtf is false and ctfType is not defined
                   (!roleUserRankingOnly && val.showOnCtf && stack && stack.ctf_type === val.ctfType) || // when showOnCtf is true and ctf_type of stack matching ctfType of menu item
                   (stack && !stack.is_ctf && !val.showOnCtf) || // when stack is non-ctf and showOnCtf is false
                   (roleSuperAdmin && val.hideOnCtf === true) // when user is admin or super admin and menu item was marked to hide on ctf stack
                 )
            )
              && (
                <Menu.Item className='menu-top-left' style={{ marginLeft: '20px' }} key={i.toString()} onClick={this.props.afterMenuClick}>
                  <Link to={val.path}>{/*renderIcon(val.ico)*/} <span>{messageTypes[language][val.name]}</span></Link>
                </Menu.Item>
                )
          )}

          <>{(roleSuperAdmin || roleAdmin)
            ? (
              <SubMenu
              key='sub-settings'  title={
                roleSuperAdmin && false
                ? (
                  <span>
                  <Link to='/settings'>
                  <SettingOutlined /> <span>{messageTypes[language].menu_settings}</span>
                  </Link>
                  </span>
                )
                : <span className='option-active'><SettingOutlined /> <span>{messageTypes[language].menu_settings}</span></span>
              }
              >
              <MenuItemGroup>
              {
                settingPathNames.map((val, i) =>
                (val.authAdmin && (roleSuperAdmin || roleAdmin)) || (val.authSuperAdmin && roleSuperAdmin)
                ? (
                  <Menu.Item key={`${i + 7}`}>
                  <Link to={val.path}>{renderIcon(val.ico)} <span>{messageTypes[language][val.name]}</span></Link>
                  </Menu.Item>
                )
                : ''
              )
            }
            </MenuItemGroup>

            </SubMenu>
          )
          : <span></span>}</>

          </Menu>
</>
    )
    //               {/*<Menu.Item className='menu-top-left' style={{ marginLeft: '20px' }}>*/}

    // {/*(roleSuperAdmin || roleAdmin) ? <Menu.Divider className='admin-divider' key='admin-divider' /> : <span></span>*/}
    // {/*</Menu>*/}
  }
}

export default Navbar
