import React from 'react'
import Highlighter from 'react-highlight-words'
import { Table, Input, Button, Divider } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { messageTypes } from '../../../../actions/messages'

class StyledSemesterClasssTable extends React.Component {
  state = {
    columns: []
  }

  settings = {
    bordered: false,
    loading: false,
    pagination: { position: 'bottom' },
    size: 'default',
    showHeader: true
  }

  getColumnSearchProps = (dataIndex, language) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => { this.searchInput = node }}
          placeholder={messageTypes[language]['table_search_' + dataIndex]}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          {messageTypes[language].search}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size='small'
          style={{ width: 90 }}
        >
          {messageTypes[language].clear}
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined className={filtered ? 'active' : ''} />,
    onFilter: (value, record) => record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    )
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  setColumns (language) {
    this.setState({
      columns: [
        {
          title: messageTypes[language].name_name,
          dataIndex: 'name',
          key: 'name',
          ...this.getColumnSearchProps('name', language),
          sorter: (a, b) => { return a.name.localeCompare(b.name) }
        },
        {
          title: messageTypes[language].leader,
          dataIndex: 'leader',
          key: 'leader',
          hidden: this.props.user?.role !== 0,
          render: (leaders, record) => leaders?.map(leader => leader.name).join(', ')
        },
        {
          title: messageTypes[language].attached_users_count,
          dataIndex: 'users',
          key: 'users',
          render: (data, record) => data?.length || 0
        },
        {
          title: messageTypes[language].stack_types,
          dataIndex: 'stack_types',
          key: 'stack_types',
          ...this.getColumnSearchProps('stack_types', language),
          render: (text, record) => record.stack_types?.map((stackType, i) => { return <p key={i}>{stackType.stack_type + ' (' + stackType.content_pack_version + ')'}</p> }),
          hidden: this.props.user?.role !== 0
        },
        {
          title: messageTypes[language].actions,
          key: 'action',
          render: (text, record) => (
            <>
              <a href='#add-user' className='show-btn' onClick={() => { this.props.showUserAccessModal(record._id) }}>{messageTypes[language].manage_access}</a>
              {this.props.user?.role === 0 && (
                <>
                  <Divider type='vertical' />
                  <a href='#edit' className='show-btn' onClick={() => { this.props.setDataId(record._id) }}>{messageTypes[language].edit}</a>
                  <Divider type='vertical' />
                  <a href='#delete' className='delete-btn' onClick={() => { this.props.deleteRecord(record._id) }}>{messageTypes[language].delete}</a>
                </>
              )}
            </>
          )
        }
      ].filter(x => !x.hidden)
    })
  }

  componentDidMount () {
    const { language } = this.props
    this._isMounted = true
    this.setColumns(language)
  }

  componentDidUpdate (prevProps, prevState) {
    const { language } = this.props
    if (this._isMounted && language !== prevProps.language) {
      this.setColumns(language)
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { columns } = this.state
    const { data, language } = this.props

    const columnsRow = [
      {
        title: messageTypes[language].name_name,
        dataIndex: 'name',
        key: 'name',
        ...this.getColumnSearchProps('name', language),
        sorter: (a, b) => { return a.name.localeCompare(b.name) },
        render: (text, record) => (
          record.status === 'draft' ? text + '*' : text
        )
      }
    ]

    return (
      <Table
        className={`settings-table ${data && data.length ? 'fade-in' : ''}`}
        {...this.settings}
        columns={columns}
        dataSource={data}
        expandable={{
          expandedRowRender: record =>
            <Table
              className={`settings-table ${data && data.length ? 'fade-in' : ''}`}
              {...this.settings}
              columns={columnsRow}
              dataSource={record.users}
              locale={{
                triggerDesc: messageTypes[language].table_sort_desc,
                triggerAsc: messageTypes[language].table_sort_asc,
                cancelSort: messageTypes[language].table_sort_cancel
              }}
            />,
          rowExpandable: record => record.users && record.users.length
        }}
        locale={{
          triggerDesc: messageTypes[language].table_sort_desc,
          triggerAsc: messageTypes[language].table_sort_asc,
          cancelSort: messageTypes[language].table_sort_cancel
        }}
      />
    )
  }
}

export default StyledSemesterClasssTable
