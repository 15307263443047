import React from 'react'
import { Modal } from 'antd'
import { messageTypes } from '../../../../actions/messages'
import UserAccessForm from '../../../../styledComponents/sections/settings/semesterClass/accessForm'
import { usersGet } from '../../../../actions/'
import { takeElementWithIdFromArr, getCookie, openNotificationPopup } from '../../../../actions/helpers'

class ClassUserAccessModal extends React.Component {
  state = {
    singleData: {}
  }

  handleCancel = () => {
    this.props.setDataId(null)
  }

  componentDidMount () {
    this._isMounted = true
    this.getUsers()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  getUsers = () => {
    const { language } = this.props

    usersGet(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            users: json.response?.filter(user => ![0, 1].includes(user.role))
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  componentDidUpdate (prevProps, prevState) {
    const { dataId, data } = this.props

    if (prevProps.dataId !== dataId) {
      const singleData = takeElementWithIdFromArr(dataId, data)
      this.setState({ singleData })
    }
  }

  render () {
    const {
      language, dataId, isNewRecord, loading, syncUsers
    } = this.props
    const { singleData } = this.state

    return (
      <div>
        <Modal
          maskClosable={false}
          width={1000}
          visible={!!(dataId || isNewRecord)}
          title={(singleData?.name && singleData?.name[language]) || messageTypes[language].manage_access}
          onCancel={this.handleCancel}
          footer={null}
        >
          <UserAccessForm
            isNewRecord={isNewRecord}
            dataId={dataId}
            data={singleData || {}}
            language={language}
            onCancel={this.handleCancel}
            syncUsers={syncUsers}
            loading={loading}
            users={this.state.users}
          />
        </Modal>
      </div>
    )
  }
}

export default ClassUserAccessModal
