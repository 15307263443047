import React, { Fragment } from 'react'
import { messageTypes } from '../../../../actions/messages'
import { numberToArray } from '../../../../actions/helpers'
import settings from '../../../../config/'
import {
  Form, Select, Input, Button, Divider, InputNumber
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
const { Option } = Select
const { TextArea } = Input

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

class StyledUserForm extends React.Component {
  formRef = React.createRef()
  state = {
    rows: []
  }

  handleSubmit = (values) => {
    const { data, isNewRecord, addRecord, updateRecord } = this.props
    const obj = {}
    obj.scenario = values.scenario
    obj.display_name = {}
    obj.display_name.pl = values.display_name_pl
    obj.display_name.en = values.display_name_en
    obj.desc = {}
    obj.desc.pl = values.desc_pl
    obj.desc.en = values.desc_en
    obj.desc_short = {}
    obj.desc_short.pl = values.desc_short_pl
    obj.desc_short.en = values.desc_short_en

    // obj['time'] = values['time'];
    obj.score = values.score
    obj.roles = values.roles
    obj.order = values.order
    obj.data = []

    for (let i = 1; i <= parseInt(values.number_of_questions); i++) {
      const answObj = {}
      answObj.question = {
        name:
          {
            pl: '',
            en: ''
          },
        desc: {
          pl: '',
          en: ''
        }
      }
      answObj.question.name.pl = values['question_name_pl_' + i]
      answObj.question.name.en = values['question_name_en_' + i]
      answObj.question.desc.pl = values['question_desc_pl_' + i]
      answObj.question.desc.en = values['question_desc_en_' + i]
      answObj.answers = values['answers_' + i].split(',')
      obj.data.push(answObj)
    }
    if (isNewRecord) { addRecord(obj) } else { updateRecord(data._id, obj) }
  }

  checkIfGt0 = (rule, value, callback) => {
    if (value > 0) {
      callback()
      return
    }
    callback(messageTypes[this.props.language].quiz_at_least_1_question)
  }

  setDefaultValues = () => {
    const { data, isNewRecord } = this.props
    if (data && data.data && !isNewRecord) {
      this.setState({
        rows: numberToArray(data.data.length)
      }, () => {
        const fieldsVal = {
          scenario: data.scenario?._id,
          display_name_pl: data.display_name?.pl,
          display_name_en: data.display_name?.en,
          desc_pl: data.desc.pl,
          desc_en: data.desc.en,
          desc_short_pl: data.desc_short?.pl,
          desc_short_en: data.desc_short?.en,
          // time: data.time,
          score: data.score,
          number_of_questions: data.data.length,
          roles: data.roles,
          order: data.order ? data.order : 0
        }
        for (let i = 0; i < data.data.length; i++) {
          fieldsVal['question_name_pl_' + (i + 1)] = data.data[i].question.name.pl
          fieldsVal['question_name_en_' + (i + 1)] = data.data[i].question.name.en
          fieldsVal['question_desc_pl_' + (i + 1)] = data.data[i].question.desc.pl
          fieldsVal['question_desc_en_' + (i + 1)] = data.data[i].question.desc.en
          fieldsVal['answers_' + (i + 1)] = data.data[i].answers.join(',')
        }
        this.formRef.current.setFieldsValue(fieldsVal)
      })
    } else {
      this.setState({
        rows: []
      }, () => {
        this.formRef.current.setFieldsValue({
          scenario: '',
          display_name_pl: '',
          display_name_en: '',
          desc_pl: '',
          desc_en: '',
          desc_short_pl: '',
          desc_short_en: '',
          // time: '',
          score: '',
          number_of_questions: '',
          roles: [],
          order: 0
        })
      })
    }
  }

  componentDidMount () {
    this.setDefaultValues()
  }

  componentDidUpdate (prevProps, prevState) {
    const { data, isNewRecord, contentPacks } = this.props
    if (data._id !== prevProps.data._id || isNewRecord !== prevProps.isNewRecord) {
      this.setDefaultValues()
    }

    if (contentPacks?.length > prevProps.contentPacks?.length) {
      const fromDisabled = contentPacks?.find(x => x.stack_type === data.stack_type)?.installed_versions?.find(x => x.name === data.content_pack_version)?.status === 'final'

      this.setState({ fromDisabled })
    }
  }

  changeInputsNumber = (e) => {
    const num = parseInt(e.target.value)
    if (num) {
      this.setState({ rows: numberToArray(num) })
    }
  }

  render () {
    const { language, loading, scenarios } = this.props
    const { rows, fromDisabled } = this.state

    return (
      <Form onFinish={this.handleSubmit} layout='horizontal' ref={this.formRef}>

        <Form.Item
          name='scenario'
          label={messageTypes[language].scenario}
          rules={[{ required: true, message: messageTypes[language].quiz_scenario_no_name_specified }]}
          {...formItemLayout}
        >
          <Select disabled={fromDisabled}>
            {scenarios.filter(val => fromDisabled || this.props.contentPacks?.find(x => x.stack_type === val.stack_type)?.installed_versions?.find(x => x.name === val.content_pack_version)?.status === 'draft').map((val, i) => <Option key={i} value={val._id}>{val.name[language]} ({val.stack_type}: {val.content_pack_version})</Option>)}
          </Select>
        </Form.Item>

        <Form.Item
          name='display_name_pl'
          label={messageTypes[language].quiz_display_name_pl}
          {...formItemLayout}
        >
          <Input disabled={fromDisabled} />
        </Form.Item>

        <Form.Item
          name='display_name_en'
          label={messageTypes[language].quiz_display_name_en}
          {...formItemLayout}
        >
          <Input disabled={fromDisabled} />
        </Form.Item>

        <Form.Item
          name='desc_short_pl'
          label={messageTypes[language].quiz_desc_short_pl}
          {...formItemLayout}
        >
          <TextArea rows={4} disabled={fromDisabled} />
        </Form.Item>

        <Form.Item
          name='desc_short_en'
          label={messageTypes[language].quiz_desc_short_en}
          {...formItemLayout}
        >
          <TextArea rows={4} disabled={fromDisabled} />
        </Form.Item>

        <Form.Item
          name='desc_pl'
          label={messageTypes[language].quiz_desc_pl}
          rules={[{ required: true, message: messageTypes[language].quiz_scenario_no_desc_pl }]}
          {...formItemLayout}
        >
          <TextArea rows={4} disabled={fromDisabled} />
        </Form.Item>

        <Form.Item
          name='desc_en'
          label={messageTypes[language].quiz_desc_en}
          {...formItemLayout}
        >
          <TextArea rows={4} disabled={fromDisabled} />
        </Form.Item>

        {/*
          <Form.Item label={ messageTypes[language]['quiz_time_seconds'] }
          {...formItemLayout}>
            { getFieldDecorator('time', {
              rules: [{ required: true, message: messageTypes[language]['quiz_no_seconds'] }],
            })(
              <Input placeholder={messageTypes[language]['seconds']} maxLength={10}/>
            )}
          </Form.Item>
        */}

        <Form.Item
          name='score'
          label={messageTypes[language].score}
          rules={[{ required: true, message: messageTypes[language].quiz_no_score }]}
          {...formItemLayout}
        >
          <Input placeholder={messageTypes[language].score_for_quiz} maxLength={10} disabled={fromDisabled} />
        </Form.Item>

        <Form.Item
          name='roles'
          label={messageTypes[language].view_for_roles}
          {...formItemLayout}
        >
          <Select
            mode='multiple'
            disabled={fromDisabled}
            filterOption={(input, option) =>
              (option.children ?? '').toLowerCase().includes(input.toLowerCase())
            }
          >
            {
              settings.roles.length
                ? settings.roles.map((element, n) =>
                  <Option key={n} value={element.role}>{messageTypes[language]['role_' + element.name]}</Option>
                )
                : ''
            }
          </Select>
        </Form.Item>

        <Form.Item
          name='order'
          label={messageTypes[language].order}
          {...formItemLayout}
        >
          <InputNumber min={0} max={255} disabled={fromDisabled} />
        </Form.Item>

        <h2 className='custom-center'>PYTANIA I ODPOWIEDZI</h2>

        <Form.Item
          name='number_of_questions'
          label={messageTypes[language].number_of_questions}
          rules={[{ validator: this.checkIfGt0 }]}
          {...formItemLayout}
        >
          <Input onChange={(e) => this.changeInputsNumber(e)} placeholder={messageTypes[language].number_of_questions} maxLength={2} disabled={fromDisabled} />
        </Form.Item>

        {
          rows.map((val, i) =>
            <Fragment key={i}>
              <Form.Item
                name={'question_name_pl_' + (i + 1)}
                label={messageTypes[language]._question_name_pl + ' ' + (i + 1)}
                {...formItemLayout}
              >
                <Input disabled={fromDisabled} />
              </Form.Item>
              <Form.Item
                name={'question_name_en_' + (i + 1)}
                label={messageTypes[language]._question_name_en + ' ' + (i + 1)}
                {...formItemLayout}
              >
                <Input disabled={fromDisabled} />
              </Form.Item>
              <Form.Item
                name={'question_desc_pl_' + (i + 1)}
                label={messageTypes[language]._question_desc_pl + ' ' + (i + 1)}
                {...formItemLayout}
              >
                <Input disabled={fromDisabled} />
              </Form.Item>
              <Form.Item
                name={'question_desc_en_' + (i + 1)}
                label={messageTypes[language]._question_desc_en + ' ' + (i + 1)}
                {...formItemLayout}
              >
                <Input disabled={fromDisabled} />
              </Form.Item>
              <Form.Item
                name={'answers_' + (i + 1)}
                label={messageTypes[language]._answers + ' ' + (i + 1)}
                {...formItemLayout}
              >
                <Input placeholder={messageTypes[language].answers_split_by_comma} disabled={fromDisabled} />
              </Form.Item>
              {i < rows.length - 1 ? <hr className='my-2' /> : ''}

            </Fragment>
          )
        }

        <Form.Item className='form-footer'>
          <Divider />
          <Button htmlType='submit' disabled={fromDisabled}>{loading ? <LoadingOutlined /> : ''} {messageTypes[language].submit}</Button>
          <Button onClick={() => { this.props.onCancel() }} className='cancel'>{messageTypes[language].cancel}</Button>
        </Form.Item>
      </Form>
    )
  }
}

export default StyledUserForm
