import { Component } from 'react'
import { connect } from 'react-redux'
import {
  setSocketId, setScenarioModalVisible,
  setMachineModalVisible, setVpnModalVisible, setMapLegendModalVisible,
  setMapObjectModalVisible, setMapNodeModalVisible, setDoneModalVisible,
  setConsoleOpened, setUseBackendVmsCheck, setChangedVms, setChallengeModalVisible,
  updateRanking
} from '../../store/actions'
import { getCookie } from '../../actions/helpers'
import { messageTypes } from '../../actions/messages'
import { withRouter } from 'react-router-dom'
import Swal from 'sweetalert2'
import config from '../../config/'
import moment from 'moment'

import socketIOClient from 'socket.io-client'

class StackChangedPrompt extends Component {
  componentDidMount () {
    const { language, setStack, setSocketId, setUseBackendVmsCheck, setChangedVms, updateRanking } = this.props
    this._isMounted = true

    const socket = socketIOClient(config.socket_url, {
      query: { token: getCookie('_token') }
    })

    const useBackendVmsCheck = function (flag) {
      socket.emit('UseVmsCheck', flag)
    }

    setUseBackendVmsCheck(useBackendVmsCheck)

    // get socket id and save it to store
    socket.on('SocketId', data => {
      setSocketId(data)

      // check current route, if neccessary enable vms checking from backend
      if (window.location.pathname === '/' || window.location.pathname === '/machines') {
        useBackendVmsCheck(true)
      } else {
        useBackendVmsCheck(false)
      }
    })

    // when vms status changed, set changed vms to store
    socket.on('VMStatusChanged', data => {
      setChangedVms(data)
    })

    // define what to call when ranking has been updated
    socket.on('UpdateRanking', data => {
      updateRanking(moment().unix())
    })

    // call refresh data when stack change
    socket.on('StackChanged', data => {
      this.props.unattendedAction && this.props.unattendedAction()

      // console.log('StackChanged', data, this.props.stackPrev)

      if (this.props.refreshData && data?.stack !== this.props.stackPrev?.nr) {
        Swal.fire({
          title: messageTypes[language].stack_changed,
          text: messageTypes[language].stack_changed_info,
          showCancelButton: true,
          confirmButtonText: messageTypes[language].refresh,
          cancelButtonText: messageTypes[language].cancel
        }).then((result) => {
          // when user confirmed, refresh data
          setStack && setStack('switching')

          if (result.value) {
            this.props.refreshData()

            this.props.setScenarioModalVisible(false)
            this.props.setMachineModalVisible(false)
            this.props.setVpnModalVisible(false)
            this.props.setMapLegendModalVisible(false)
            this.props.setMapObjectModalVisible(false)
            this.props.setMapNodeModalVisible(false)
            this.props.setDoneModalVisible(false)
            this.props.setConsoleOpened(false)
            this.props.setChallengeModalVisible(false)

            // if current path is challenges and target stack is not of type jeopardy, redirect to home
            if (window.location.pathname === '/challenges' && data.ctf_type !== 'jeopardy') {
              this.redirect('/')
            }
          }
        })
      }

      // check current route, if neccessary enable vms checking from backend
      if (window.location.pathname === '/' || window.location.pathname === '/machines') {
        useBackendVmsCheck(true)
      } else {
        useBackendVmsCheck(false)
      }
    })

    socket.on('StackStatusChanged', data => {
      this.props.refreshData && this.props.refreshData()
    })
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  redirect = (path) => {
    this.props.history.push(path)
  }

  render () {
    return null
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language,
  stack: state.hdStore.stack,
  stackPrev: state.hdStore.stackPrev
})

const mapDispatchToProps = {
  setSocketId,
  setScenarioModalVisible,
  setMachineModalVisible,
  setVpnModalVisible,
  setMapLegendModalVisible,
  setMapObjectModalVisible,
  setMapNodeModalVisible,
  setDoneModalVisible,
  setConsoleOpened,
  setUseBackendVmsCheck,
  setChangedVms,
  setChallengeModalVisible,
  updateRanking
}

const StackChangedPromptContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StackChangedPrompt)

export default withRouter(StackChangedPromptContainer)
