import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Input, Button } from 'antd'
import { messageTypes } from '../../../actions/messages'
import { Link, withRouter } from 'react-router-dom'
import { signin } from '../../../actions/'
import { openNotificationPopup, setCookie } from '../../../actions/helpers'
import { setAuth, setUser, setStack, setUserStacks, setCtfState /*, setCurrentStackMachines */ } from '../../../store/actions'

import config, { views } from '../../../config/'

class NormalLoginForm extends Component {
  handleLogin = (values) => {
    const { setAuth, setUser, setStack, setUserStacks, setCtfState, /* setCurrentStackMachines, */ language } = this.props
    const { email, password } = values

    setCtfState(undefined)

    signin(email, password).then((json) => {
      if (json.status === 'ok') {
        const { token, name, email, expires, view, stack, stacks, role, team_token: teamToken } = json.auth
        setCookie('_token', token, expires)
        const userData = { name, email, team_token: teamToken, role }
        // user info save to localStorage
        window.localStorage.setItem('user', JSON.stringify(userData))

        if (view && view.length === 32) {
          setAuth(view)
          setCookie('_view', view)
        } else { setAuth(true) }

        setUser(userData)
        setUserStacks(stacks)
        setStack(stack)

        // if substacks in stack are defined try set machines visibility list
        // if (stack.substacks && stack.substacks.length) {
        //   const currentSubstack = stack.substacks.find(x => x.name === stack.current_type)
        //
        //   if (currentSubstack) {
        //     setCurrentStackMachines(currentSubstack.machines_visible)
        //   }
        // }

        openNotificationPopup(messageTypes[language].success, json.response[language], 'smile')

        if (view === views.ur) {
          this.props.history.push('/ranking')
        } else {
          this.props.history.push('/')
        }
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
      }
    })
  }

  render () {
    const { language, nameFieldRef } = this.props

    return (
      <div>
        <Form onFinish={this.handleLogin} className='login-form'>
          <Form.Item
            name='email'
            rules={[{ required: true, message: messageTypes[language].login_name_err }]}
          >
            <div>
              <p className='field-label'>{messageTypes[language].login_name}:</p>
              <Input ref={nameFieldRef} />
            </div>
          </Form.Item>
          <Form.Item
            name='password'
            rules={[{ required: true, message: messageTypes[language].login_password_err }]}
          >
            <div>
              <p className='field-label'>{messageTypes[language].login_password}:</p>
              <Input type='password' />
            </div>
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit' className='login-form-button'>
              {messageTypes[language].log_in}
            </Button>
            <>
              {config.moodle_oauth_enabled && <p className='login-link'><a href={config.moodle_url + '/local/oauth/login.php?client_id=' + config.moodle_client_id + '&response_type=code'}>{messageTypes[language].signin_moodle}</a></p>}
              {config.openid_enabled && <p className='login-link'><a href={config.api_url + '/openid'}>{messageTypes[language].signin_via} {config.openid_link_text}</a></p>}
              {config.registration && <p className='login-link'><Link to='/register'>{messageTypes[language].register_now}</Link></p>}
              {config.password_reset && <p className={!config.registration ? 'login-link' : undefined}><Link to='/reset-password'>{messageTypes[language].reset_password_link}</Link></p>}
            </>
          </Form.Item>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.hdStore.auth
})

const mapDispatchToProps = {
  setAuth,
  setUser,
  setStack,
  setUserStacks,
  setCtfState
  // setCurrentStackMachines
}

const WrappedNormalLoginFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NormalLoginForm)

export default withRouter(WrappedNormalLoginFormContainer)
