import React from 'react'
import { messageTypes } from '../../../../actions/messages'
import {
  Form, Button, Divider, Transfer
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import settings from '../../../../config/'

class StyledUserAccessForm extends React.Component {
  state = {
  }

  formRef = React.createRef()

  handleSubmit = (values) => {
    this.props.syncUsers(this.props.data._id, this.state.dataSelected)
  }

  setDefaultValues = () => {
    const { data } = this.props

    this.setState({
      users: data,
      dataSelected: data?.users?.map(x => x._id)
    })
  }

  componentDidMount () {
    this.setDefaultValues()
  }

  componentDidUpdate (prevProps, prevState) {
    const { data, isNewRecord } = this.props

    if (data._id !== prevProps.data._id || isNewRecord !== prevProps.isNewRecord) {
      this.setDefaultValues()
    }
  }

  handleChange = (dataSelected) => {
    this.setState({ dataSelected })
  }

  render () {
    const { language, loading } = this.props
    const { dataSelected } = this.state

    return (
      <Form onFinish={this.handleSubmit} layout='horizontal' ref={this.formRef}>
        <Transfer
          dataSource={this.props.users}
          rowKey={(record) => record._id}
          showSearch
          titles={[messageTypes[language].semester_class_available_users, messageTypes[language].semester_class_users_in_group]}
          disabled={settings.oauth}
          targetKeys={dataSelected}
          onChange={this.handleChange}
          locale={{ itemUnit: messageTypes[language].semester_class_user, itemsUnit: messageTypes[language].semester_class_users, notFoundContent: messageTypes[language].list_is_empty, searchPlaceholder: messageTypes[language].search }}
          listStyle={{
            width: '100%',
            height: 400
          }}
          render={(item) => item.name}
        />
        <Form.Item className='form-footer'>
          <Divider />
          <Button htmlType='submit' disabled={loading}>{loading ? <LoadingOutlined /> : ''} {messageTypes[language].submit}</Button>
          <Button disabled={loading} onClick={() => { this.props.onCancel() }} className='cancel'>{messageTypes[language].cancel}</Button>
        </Form.Item>
      </Form>
    )
  }
}

export default StyledUserAccessForm
