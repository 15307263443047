import React from 'react'
import { messageTypes } from '../../../../actions/messages'
import {
  Form, Input, Button, Divider, InputNumber
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import ResourceFilesFormComponent from '../../../common/filesWithUploadForm'

class StyledMapIconForm extends ResourceFilesFormComponent {
  formRef = React.createRef()
  forceExtension = 'svg'
  fileTypes = ['default', 'done', 'undone', 'unactive']
  disableFileLabel = true

  componentDidMount () {
    this.setDefaultValues()

    this.setState({ languages: [this.state.languages[0]] })
  }

  componentDidUpdate (prevProps, prevState) {
    const { data, isNewRecord, contentPackVersions } = this.props

    if (data._id !== prevProps.data._id || isNewRecord !== prevProps.isNewRecord) {
      this.setDefaultValues()
    }

    if (data._id !== prevProps.data._id || data === null) {
      this.setState({ fromDisabled: false })
    }

    if (contentPackVersions?.length > prevProps.contentPackVersions?.length) {
      const fromDisabled = contentPackVersions?.find(x => x.name === data.content_pack_version)?.status === 'final'

      this.setState({ fromDisabled })
    }
  }

  prepareFormValues (values) {
    return {
      name: values.name,
      size: values.size
    }
  }

  prepareFormExistingValues (data) {
    return {
      name: data.name,
      size: data.size
    }
  }

  prepareFormDefaultValues () {
    return {
      name: '',
      size: 32
    }
  }

  addFile = (challengeId, callAfterCreate) => {
    const { addFile } = this.props
    addFile && addFile(challengeId).then(result => callAfterCreate(result))
  }

  removeSingleFile = (challengeId, lang, callAfterCreate) => {
    const { removeSingleFile } = this.props
    removeSingleFile && removeSingleFile(challengeId, lang).then(result => callAfterCreate && callAfterCreate(result))
  }

  removeResource = (challengeId, callAfterCreate) => {
    const { removeResource } = this.props
    removeResource && removeResource(challengeId).then(result => callAfterCreate && callAfterCreate(result))
  }

  render () {
    const { language, loading, data } = this.props
    const { fromDisabled } = this.state

    return (
      <Form onFinish={this.handleSubmit} layout='horizontal' ref={this.formRef}>

        <Form.Item
          name='name'
          label={messageTypes[language].name_name}
          rules={[
            { required: true, message: messageTypes[language].field_required },
            {
              message: messageTypes[language].invalid_icon_name,
              validator: (_, value) => {
                if (value.match(/^[a-z0-9_]+$/g)) {
                  return Promise.resolve()
                } else {
                  return Promise.reject(new Error('Invalid name, only a-z 0-9 and _ chars allowed'))
                }
              }
            }
          ]}
          {...this.formItemLayout}
        >
          <Input disabled={fromDisabled} />
        </Form.Item>

        <Form.Item
          name='size'
          label={messageTypes[language].size}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <InputNumber disabled={fromDisabled} />
        </Form.Item>

        {this.fileFieldsRender('map_icons', data.mapicon_hd_id, 'mapicons', fromDisabled)}

        <Form.Item className='form-footer'>
          <Divider />
          <Button htmlType='submit'>{loading ? <LoadingOutlined /> : ''} {messageTypes[language].submit}</Button>
          <Button onClick={() => { this.props.onCancel() }} className='cancel'>{messageTypes[language].cancel}</Button>
        </Form.Item>
      </Form>
    )
  }
}

export default StyledMapIconForm
