import React, { Component } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

class StyledCardBody extends Component {
  componentDidMount () {
    this._scrollBarRef && this._scrollBarRef.updateScroll()
  }

  render () {
    const { handleClick, element, language } = this.props
    const { desc, desc_short: shortDesc } = element
    const langDesc = (shortDesc && shortDesc[language]) || (desc && desc[language])
    const brDesc = langDesc && langDesc.split('\n')

    return (
      <div className='hoverable' onClick={() => { handleClick(element._id) }}>
        <PerfectScrollbar style={{ textAlign: 'left' }} ref={(ref) => { this._scrollBarRef = ref }}>
          {brDesc && brDesc.map((val, i) => (
            <div key={i}>{val} <br /></div>
          ))}
        </PerfectScrollbar>
      </div>
    )
  }
}

export default StyledCardBody
