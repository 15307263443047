import React from 'react'
import config from '../../config/'
import { messageTypes } from '../../actions/messages'
import { switchStack } from '../../actions'
import { openNotificationPopup, getCookie, confirm } from '../../actions/helpers'

class StackSwitchComponent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      switching: false,
      switching_to: null
    }
  }

  doSwitch = (targetStack, after) => {
    const { /* setCurrentStackMachines, */ setStack, setStackPrev, stack, socketId, setStackActionStatus } = this.props

    const changeStackState = !!(document.getElementById('confirm_checkbox') && document.getElementById('confirm_checkbox').checked)

    setStackPrev(targetStack)

    // console.log('doSwitch', after)
    setStack('switching')
    this.setState({ switching: true, switching_to: targetStack })

    switchStack(targetStack._id, changeStackState, socketId, getCookie('_token')).then((json) => {
      if (json.status === 'ok') {
        // setCurrentStackMachines(stack.machines_visible)
        setStack(json.stack)

        if (json.stack.status === 'reverting' || json.stack.status === 'poweringon' || json.stack.status === 'suspending') {
          setStackActionStatus(json.stack.status)
        } else {
          setStackActionStatus(undefined)
        }

        // if current path is challenges and target stack is not of type jeopardy, redirect to home
        if (window.location.pathname === '/challenges' && json.stack.ctf_type !== 'jeopardy') {
          this.redirect('/')
        }

        openNotificationPopup(messageTypes[this.props.language].success, json.response && json.response[this.props.language], 'smile')

        after && after()
      } else if (json.status === 'restore') {
        // restore status means that stack wasn't ready to switch into it
        setStack(stack)
        openNotificationPopup(messageTypes[this.props.language].oops, json.response && json.response[this.props.language], 'frown')
      } else {
        openNotificationPopup(messageTypes[this.props.language].oops, json.response && json.response[this.props.language], 'frown')
      }

      this.setState({ switching: false, switching_to: null })
    })
  }

  switchStack = (stack, after) => {
    // console.log('switchStack', after)
    if (config.stack_switch_state === 'poweroff' || config.stack_switch_state === 'suspend') {
      confirm('stack_switch_question', this.props.language, () => { this.doSwitch(stack, after) }, null, 'stack_switch_' + config.stack_switch_state)
    } else {
      this.doSwitch(stack, after)
    }
  }
}

export default StackSwitchComponent
