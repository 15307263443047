import React, { Component } from 'react'
import { connect } from 'react-redux'
import StyledSemesterClassTable from '../../../../styledComponents/sections/settings/semesterClass/table'
import AddRecordButton from '../../../../styledComponents/common/addRecordButton'
import { PageHeader } from 'antd'
import {
  openNotificationPopup,
  getCookie,
  confirm,
  deleteElementWithIdFromArr
} from '../../../../actions/helpers'
import {
  adminGetSemesterClasses,
  adminCreateSemesterClass,
  adminGetSemester,
  adminUpdateSemesterClass,
  adminDeleteSemesterClass,
  adminSyncSemesterClassWithUsers
} from '../../../../actions/'
import { messageTypes } from '../../../../actions/messages'
import SemesterClassModal from './modal'
import ClassUserAccessModal from './accessModal'

class SemesterClass extends Component {
  state = {
    data: [],
    userModal: false
  }

  componentDidMount () {
    const { id } = this.props.match.params
    this._isMounted = true
    this.getAllData(id)
    this.getSemester(id)

    this.setState({ id })
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  updateRecord = (id, data) => {
    const { language } = this.props
    this.setState({ loading: true })
    adminUpdateSemesterClass(id, data, getCookie('_token')).then((json) => {
      this.setState({ loading: false })
      if (json.status === 'ok') {
        this.getAllData(this.state.id)
        this.setState({
          dataId: null
        })
        openNotificationPopup(
          messageTypes[language].success,
          json.response[language],
          'smile'
        )
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
      }
    })
  }

  getSemester = (id) => {
    const { language } = this.props
    adminGetSemester(id, getCookie('_token')).then((json) => {
      this.setState({ loading: false })
      if (json.status === 'ok') {
        this.setState({ semester: json.response })
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
      }
    })
  }

  addRecord = (data) => {
    const { language } = this.props
    this.setState({ loading: true })
    adminCreateSemesterClass(this.state.id, data, getCookie('_token')).then((json) => {
      this.setState({ loading: false })
      if (json.status === 'ok') {
        this.getAllData(this.state.id)
        this.showAddRecordModal(null)
        openNotificationPopup(
          messageTypes[language].success,
          json.response[language],
          'smile'
        )
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
      }
    })
  }

  syncUsers = (id, data) => {
    const { language } = this.props
    this.setState({ loading: true })
    adminSyncSemesterClassWithUsers(id, data, getCookie('_token')).then((json) => {
      this.setState({ loading: false })
      if (json.status === 'ok') {
        this.getAllData(this.state.id)
        this.showUserAccessModal(null)
        openNotificationPopup(
          messageTypes[language].success,
          json.response[language],
          'smile'
        )
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown', null, null, 10)
      }
    })
  }

  redirect = (path) => {
    this.props.history.push(path)
  }

  getAllData = (id) => {
    const { language } = this.props

    adminGetSemesterClasses(id, getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            data: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  deleteRecord = (id) => {
    const { language } = this.props

    confirm('delete_semester_class_question', language, () => {
      adminDeleteSemesterClass(id, getCookie('_token')).then((json) => {
        if (this._isMounted) {
          if (json.status === 'ok') {
            this.setState({ data: deleteElementWithIdFromArr(id, this.state.data) })
            openNotificationPopup(
              messageTypes[language].success,
              json.response[language],
              'smile'
            )
          } else if (json.status === 'err') {
            openNotificationPopup(messageTypes[this.props.language].oops, json.response[language], 'frown')
          }
        }
      })
    })
  }

  setDataId = (dataId) => { this.setState({ dataId }) }
  showAddRecordModal = (isNewRecord) => { this.setState({ isNewRecord }) }
  showUserAccessModal = (userModal) => { this.setState({ userModal }) }

  returnWithKey (data, lang) {
    const retArr = []
    data.forEach((val, i) => {
      const obj = {}
      obj.key = i
      obj._id = val._id
      obj.name = val.name && val.name[lang]
      obj.stack_types = val.stack_types
      obj.users = val.users
      obj.leader = val.leader

      retArr.push(obj)
    })
    return retArr
  }

  render () {
    const { data, dataId, isNewRecord, semester, userModal, loading } = this.state
    const { language, user } = this.props

    return (
      <>
        <PageHeader
          onBack={() => this.redirect('/settings/semesters')}
          title={messageTypes[language].course_groups + ': ' + ((semester?.name && semester.name[language]) || '')}
        />
        {user?.role === 0 && (
          <>
            <AddRecordButton
              language={language}
              showAddRecordModal={this.showAddRecordModal}
              title={messageTypes[language].new_group}
            />
            <SemesterClassModal
              language={language}
              data={data}
              dataId={dataId}
              setDataId={this.setDataId}
              isNewRecord={isNewRecord}
              loading={loading}
              addRecord={this.addRecord}
              updateRecord={this.updateRecord}
              showAddRecordModal={this.showAddRecordModal}
              user={this.props.user}
            />
          </>)}
        <ClassUserAccessModal
          language={language}
          data={data}
          dataId={userModal}
          setDataId={this.showUserAccessModal}
          loading={loading}
          syncUsers={this.syncUsers}
        />
        <StyledSemesterClassTable
          language={language}
          data={this.returnWithKey(data, language)}
          setDataId={this.setDataId}
          deleteRecord={this.deleteRecord}
          showUserAccessModal={this.showUserAccessModal}
          user={this.props.user}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language,
  user: state.hdStore.user
})

const SemesterClassContainer = connect(
  mapStateToProps
)(SemesterClass)

export default SemesterClassContainer
