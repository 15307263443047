import React, { Component } from 'react'
import { Tag, Popover } from 'antd'
import { PoweroffOutlined, ClockCircleOutlined } from '@ant-design/icons'
import { messageTypes } from '../../actions/messages'

class StyledStatusDisplay extends Component {
  constructor (props) {
    super(props)
    this.state = {
      color: '',
      text: '',
      status: undefined
    }
  }

  componentDidMount () {
    this.describeButton(this.props.status)
  }

  componentDidUpdate (prevProps, prevState) {
    const { status } = this.props
    if (prevProps.status !== status) {
      this.describeButton(status)
    }
  }

  describeButton = (status) => {
    let color = ''
    let text = ''
    const { language } = this.props

    if (status === 'poweredOn') {
      color = 'green'
      // text = messageTypes[language].status_poweredOn
      // text = <img src='/images/icons/on.svg' alt={messageTypes[language].status_poweredOn} />
    } else if (status === 'poweredOff') {
      color = 'red'
      // text = <img src='/images/icons/off.svg' alt={messageTypes[language].status_poweredOff} />
    } else if (status === 'err' || status === 'failed') {
      color = 'red'
      // text = <img src='/images/icons/off.svg' alt={messageTypes[language].status_err} />
    } else if (status === 'turning_on' || status === 'poweringon') {
      color = 'yellow'
      text = <ClockCircleOutlined />
    } else if (status === 'turning_off') {
      color = 'yellow'
      text = <ClockCircleOutlined />
    } else if (status === 'rebooting') {
      color = 'yellow'
      text = <ClockCircleOutlined />
    } else if (status === 'reverting') {
      color = 'yellow'
      text = <ClockCircleOutlined />
    } else if (status === 'logging') {
      color = 'yellow'
      text = <ClockCircleOutlined />
    } else if (status === 'testing') {
      color = 'yellow'
      text = <ClockCircleOutlined />
    } else if (status === 'suspended') {
      color = 'red'
      // text = <img src='/images/icons/off.svg' alt={messageTypes[language].status_suspended} />
    } else if (status === 'suspending') {
      color = 'yellow'
      text = <ClockCircleOutlined />
    } else if (/^waiting-for-/.test(status)) {
      color = 'yellow'
      text = <ClockCircleOutlined />
    } else {
      color = 'yellow'
      text = <ClockCircleOutlined />
    }

    this.setState({
      color, text, status
    })
  }

  render () {
    const { status } = this.state
    const { language } = this.props
    // const statusTag = (<Tag color={this.state.color}>{this.state.text}</Tag>)
    // const statusTag = (<img src='/images/icons/on.svg' alt='on' />)
    const statusTag = this.state.text

    return (
      (status === 'failed' && <Popover content={messageTypes[language].stack_status_failed_explain} placement='bottom'>{statusTag || <PoweroffOutlined />}</Popover>) || <span className='vm-status'>{statusTag || <PoweroffOutlined />}</span>
    )
  }
}

export default StyledStatusDisplay
