import React from 'react'
import { messageTypes } from '../../../../actions/messages'
import {
  Form, Input, Button, Divider, Select, Popconfirm, Row, Col
} from 'antd'
import { LoadingOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'

const { Option } = Select

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

class StyledSemesterClassForm extends React.Component {
  state = {
    stackTypes: [],
    stackTypesCounter: 0
  }

  formRef = React.createRef()

  handleSubmit = (values) => {
    const { data, isNewRecord, addRecord, updateRecord } = this.props
    const obj = {}
    obj.name = { pl: values.name_pl, en: values.name_en }
    obj.stack_types = values.stack_types
    obj.leader = values.leader
    obj.openid_group = values.openid_group

    if (isNewRecord) {
      addRecord(obj)
    } else { updateRecord(data._id, obj) }
  }

  setDefaultValues = () => {
    const { data, isNewRecord } = this.props

    if (data && Object.keys(data).length > 0 && !isNewRecord) {
      this.setState({
        stackTypes: data.stack_types,
        stackTypesCounter: data.stack_types.length
      }, () => {
        const fieldsVal = {
          name_pl: data.name.pl,
          name_en: data.name.en,
          stack_types: data.stack_types,
          openid_group: data.openid_group,
          leader: data.leader?.map(leader => leader._id)
        }
        this.formRef.current.setFieldsValue(fieldsVal)
      })
    } else {
      this.setState({
        stackTypes: [],
        stackTypesCounter: 0
      }, () => {
        this.formRef.current.setFieldsValue({
          name_pl: '',
          name_en: '',
          openid_group: '',
          stack_types: [],
          leader: undefined
        })
      })
    }
  }

  componentDidMount () {
    this.setDefaultValues()
  }

  componentDidUpdate (prevProps, prevState) {
    const { data, isNewRecord } = this.props

    if (data._id !== prevProps.data._id || isNewRecord !== prevProps.isNewRecord) {
      this.setDefaultValues()
    }
  }

  handleUpdateStackTypes = () => {
    this.setState({ stackTypes: this.formRef.current?.getFieldValue('stack_types') })
  }

  addStackType = (add, name) => {
    const id = this.state.stackTypesCounter
    add({ id })
    this.setState({ stackTypesCounter: id + 1, stackTypes: this.formRef.current?.getFieldValue('stack_types') })
  }

  render () {
    const { language, loading, leaders, overlordStackTypes } = this.props

    const popconfirmProps = {
      okText: messageTypes[language].yes,
      cancelText: messageTypes[language].cancel,
      placement: 'bottom'
    }

    const popconfirmItemDeleteProps = {
      ...popconfirmProps,
      placement: 'left'
    }

    return (
      <Form onFinish={this.handleSubmit} layout='horizontal' ref={this.formRef}>
        <Form.Item
          name='name_pl'
          label={messageTypes[language].name_pl}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name='name_en'
          label={messageTypes[language].name_en}
          {...formItemLayout}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name='leader'
          label={messageTypes[language].leader}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <Select mode='multiple'>
            {
              leaders && leaders.length
                ? leaders.map((element, n) =>
                  <Option key={n} value={element._id}>{element.name}</Option>
                )
                : ''
            }
          </Select>
        </Form.Item>

        <Form.Item
          name='openid_group'
          label={messageTypes[language].openid_group}
          {...formItemLayout}
        >
          <Input />
        </Form.Item>

        <Divider>{messageTypes[language].stack_types}</Divider>

        <Form.List
          name='stack_types'
          rules={[
            {
              validator: async (_, names) => {
                if (!names?.length) {
                  return Promise.reject(new Error(messageTypes[language].add_stack_type))
                }
              }
            }
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <React.Fragment key={key}>
                  <Form.Item
                    {...restField}
                    name={[name, 'id']}
                    hidden
                  >
                    <Input />
                  </Form.Item>

                  <Row gutter={[3, 0]}>
                    <Col xs={24} md={10} lg={10}>
                      <Form.Item
                        {...restField}
                        name={[name, 'stack_type']}
                        rules={[{ required: true, message: messageTypes[language].field_required }]}
                        style={{ marginBottom: '10px' }}
                      >
                        <Select
                          onChange={change => this.handleUpdateStackTypes(change, name)}
                          placeholder={messageTypes[language].stack_type}
                        >
                          {overlordStackTypes && overlordStackTypes.length && overlordStackTypes.map((stackType, i) =>
                            <Option key={i} value={stackType.stack_type}>{stackType.stack_type}</Option>)}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={7} md={4} lg={4}>
                      <Form.Item
                        {...restField}
                        name={[name, 'content_pack_version']}
                        style={{ minWidth: '70px' }}
                        rules={[{ required: true, message: messageTypes[language].field_required }]}
                      >
                        <Select
                          onChange={change => this.handleUpdateStackTypes(change, name)}
                          placeholder={messageTypes[language].content_pack_version}
                        >
                          {overlordStackTypes && overlordStackTypes.length &&
                            overlordStackTypes.find(x => x.stack_type === this.state.stackTypes[name]?.stack_type)?.installed_versions?.map((version, i) =>
                              <Option key={i} value={version.name}>{version.name}</Option>)}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={3} md={2} lg={2}>
                      <Popconfirm
                        title={messageTypes[language].delete_stack_type_semester_group_question}
                        onConfirm={() => remove(name)}
                        {...popconfirmItemDeleteProps}
                      >
                        <MinusCircleOutlined
                          className='dynamic-delete-button'
                          title={messageTypes[language].delete}
                        />
                      </Popconfirm>
                    </Col>
                  </Row>
                </React.Fragment>
              ))}

              <Form.ErrorList errors={errors} />

              <Button
                type='secondary'
                onClick={() => this.addStackType(add)}
                icon={<PlusOutlined />}
              >
                {messageTypes[language].add_stack_type}
              </Button>
            </>
          )}
        </Form.List>

        <Form.Item className='form-footer'>
          <Divider />
          <Button htmlType='submit' disabled={loading}>{loading ? <LoadingOutlined /> : ''} {messageTypes[language].submit}</Button>
          <Button disabled={loading} onClick={() => { this.props.onCancel() }} className='cancel'>{messageTypes[language].cancel}</Button>
        </Form.Item>
      </Form>
    )
  }
}

export default StyledSemesterClassForm
