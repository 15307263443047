import React, { Component } from 'react'
import { connect } from 'react-redux'
import { rankingGet, getFlagSnapshots, getMapIcons, getRankingCsv } from '../../../actions'
import { messageTypes } from '../../../actions/messages'
import { openNotificationPopup, getCookie } from '../../../actions/helpers'
import StyledRanking from '../../../styledComponents/sections/ranking/'
import StyledLoader from '../../../styledComponents/common/loader'
import { options } from '../../../styledComponents/sections/settings/maps/mapDraw'
import moment from 'moment'
import { views } from '../../../config/'

const FileDownload = require('js-file-download')

class Ranking extends Component {
  state = {
    ranking: [],
    columns: [],
    scoreboardFrozen: false,
    chartData: [],
    users: [],
    extendedView: false,
    options
  }

  componentDidMount () {
    const { stack } = this.props
    const extendedView = stack?.is_ctf
    this._isMounted = true

    this.setState({ extendedView }, () => {
      this.getAllData()
      this.getMapIcons()
    })
  }

  setDataState (data) {
    const ranking = data.map((val, i) => {
      const main = {
        key: i + 1,
        name: val.name,
        color: val.color,
        tags: val.tags || [],
        score: val.score || 0
      }

      const flags = {}

      // prepare field with flags
      val.flag_data && val.flag_data.forEach((flag, i) => {
        flags['s' + i] = flag
      })

      return { ...main, ...flags }
    })

    return ranking
  }

  getMapIcons = () => {
    const { language } = this.props

    getMapIcons(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          const icons = {}

          for (const icon of json.response) {
            for (const res of icon.resources) {
              if (res.active && res.file_type === 'default') {
                icons[icon.name] = {
                  image: '/images/mapicon/' + res._id + '.svg',
                  size: icon.size,
                  shape: icon.shape
                }
              }
            }
          }

          const options = {}

          options.groups = {
            ...options.groups,
            ...icons
          }

          this.setState({
            options
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  exportRankingCsv = () => {
    const { language } = this.props
    getRankingCsv(getCookie('_token')).then((response) => {
      FileDownload(response.data, 'ranking.csv')
    })
  }

  getRanking = () => {
    const { language } = this.props
    rankingGet(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            ranking: this.setDataState(json.response.ranking),
            columns: json.response.columns,
            maxPoints: json.response.maxPoints,
            scoreboardFrozen: json.response.scoreboard_frozen
          })
        } else if (json.status === 'err') {
          openNotificationPopup(
            messageTypes[language].oops,
            json.response[language],
            'frown')
        }
      }
    })
  }

  getFlagSnapshots = () => {
    const { language } = this.props

    getFlagSnapshots(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          const userLastValues = {}
          const users = json.response.users
          const chartData = []
          const initialState = {}
          const points = {}

          for (const u of users) {
            initialState[u] = 0
            userLastValues[u] = 0
          }

          initialState.timestamp = moment(this.props.stack.ctf_start_date).unix()
          chartData.push(initialState)

          if (json.response.points_are_dynamic) {
            for (const event of json.response.flags) {
              points[event.challenge] = event.new_points
            }
          }

          for (const i of json.response.flags) {
            const check = chartData.find(x => x.timestamp === i.timestamp)

            // if specified hour exists, just update user points
            if (check) {
              userLastValues[i.username] += (json.response.points_are_dynamic && points[i.challenge]) || i.new_points
              check[i.username] = userLastValues[i.username]
            } else {
              // add new data do chart
              const row = {
                timestamp: i.timestamp
              }

              // for all users use last remembered data
              for (const u of users) {
                row[u] = userLastValues[u]
              }

              // for user from current flag capture update points to current value
              userLastValues[i.username] += (json.response.points_are_dynamic && points[i.challenge]) || i.new_points
              row[i.username] = userLastValues[i.username]

              chartData.push(row)
            }
          }

          this.setState({
            chartData
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  getAllData = () => {
    this.getRanking()
    this.state.extendedView && this.props.stack && this.getFlagSnapshots()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  // checkFlagCaptures = () => {
  //   let flagCaptures = this.state.flagCaptures
  //   const toRemove = []
  //
  //   for (const flg in flagCaptures) {
  //     flagCaptures[flg].last_update = moment()
  //
  //     if ( moment.duration(moment().diff(moment(flagCaptures[flg].created_at))).asSeconds() > 20) {
  //       toRemove.push(String(flagCaptures[flg]._id))
  //     }
  //   }
  //
  //   this.setState({ flagCaptures: flagCaptures.filter(x => !toRemove.includes(String(x._id))) })
  // }

  componentDidUpdate (prevProps, prevState) {
    const { ctfState, updateRankingTime, stack } = this.props

    // if ctf just started get ranking
    if ((ctfState !== prevProps.ctfState && ctfState === 'BATTLE') || prevProps.updateRankingTime !== updateRankingTime || prevProps.stack !== this.props.stack) {
      this.setState({ extendedView: stack?.is_ctf }, () => {
        this.getAllData()
      })
    }

    // if (JSON.stringify(prevProps.updateRankingTime2) !== JSON.stringify(updateRankingTime2)) {
    //   const flagCaptures = this.state.flagCaptures
    //   const d2d2 = updateRankingTime2 || []
    //
    //   for (const ddd of d2d2) {
    //     ddd.key = ddd._id
    //   }
    //
    //   let interval = this.state.interval
    //
    //   // TODO: cleanup interval when list is empty or when component will unmount
    //   if (this.state.interval === undefined) {
    //     interval = setInterval(() => {
    //       this.checkFlagCaptures()
    //     }, 5000);
    //   }
    //
    //   this.props.updateRanking2([])
    //
    //   this.setState({ interval, flagCaptures: [...flagCaptures, ...d2d2] })
    // }
  }

  render () {
    const { ranking, columns, scoreboardFrozen, maxPoints, chartData, extendedView } = this.state
    const { stack, language, auth } = this.props

    const adminAuth = (auth === views.sa || auth === views.ad)


    return (
      <>
        <StyledRanking
          language={language}
          ranking={ranking}
          columns={columns}
          maxPoints={maxPoints}
          scoreboardFrozen={scoreboardFrozen}
          exportRankingCsv={this.exportRankingCsv}
          chartData={chartData}
          stack={stack}
          extendedView={extendedView}
          options={this.state.options}
          adminAuth={adminAuth}
        />
        <StyledLoader fadeOut={ranking.length && stack !== 'switching'} fadeIn={!ranking.length || stack === 'switching'} />
      </>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.hdStore.auth,
  language: state.hdStore.language,
  stack: state.hdStore.stack,
  ctfState: state.hdStore.ctfState,
  updateRankingTime: state.hdStore.updateRankingTime
})

const RankingContainer = connect(
  mapStateToProps
)(Ranking)

export default RankingContainer
